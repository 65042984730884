.confman {
    $tracks: #983620, #c7910c,#134f73;
    $tracks-lighter:#ab4b34,#d8a61d,#16628f;
    $main-color: #233166;

    $big-titles: 20px;
    &.conference-agenda--CEX {
        .curved-cta--primary {
            background-color: $main-color;
            .curved-cta__link {
                color: #fff;
            }
            &:hover {
                background-color: #fff;
                box-shadow: inset 0 0 0px 1.5px $main-color;
                .curved-cta__link {
                    color: $main-color;
                }
            }
            &.curved-cta--hard {
                background: #3d55b2;
                &:hover,
                &.active {
                    background: $main-color;
                    box-shadow: unset;
                    .curved-cta__link {
                        color: #fff;
                    }
                }
            }
        }
        .confman__main-nav {
            .main-nav__days {
                $inactive-color: #3d55b2;
                background-color: $inactive-color;
                .main-nav__nav-button {
                    background-color: $inactive-color;
                    &.active {
                        background-color: $main-color;
                        &:hover {
                            //background-color: darken($main-color, 20);
                        }
                    }
                    &:hover {
                        background-color: $main-color;
                        transition: background-color 0.25s ease-in;
                        -webkit-transition: background-color 0.25s ease-in;
                    }
                }
            }
            .main-nav__tracks {
                background-color: #e9e7f4;
                .checkbox-filter {
                    .checkbox-filter__name {
                        margin-top: 0;
                        h3 {
                            color: #333333;
                        }
                    }
                    $i: 1;
                    @each $track in $tracks {
                        &.checkbox-filter--track-#{$i} {
                            .bi-check-square-fill,
                            .bi-square {
                                color: nth($tracks, $i);
                            }
                        }
                        $i: $i + 1;
                    }
                }
            }
        }
        .react__body {
            .body__session {
                $i: 1;
                @each $track in $tracks {
                    &.session--track-#{$i} {
                        .session__flag {
                            background-color: nth($tracks, $i);
                            border-top: 15px solid nth($tracks-lighter, $i);
                        }
                        .session__title {
                            h3 {
                                color: nth($tracks, $i);
                            }
                        }
                        .session__body {
                            .body__speakers {
                                & > h3 {
                                    color: nth($tracks, $i);
                                }
                                .speaker__presentation-name {
                                    display:none;
                                    color: nth($tracks, $i);
                                }
                                .speaker__info {
                                    &__name {
                                        color: nth($tracks, $i);
                                    }
                                    &__presentation {
                                        color: nth($tracks, $i);
                                    }
                                }
                            }
                        }
                    }
                    $i: $i + 1;
                }
                .session__flag {
                }
                .session__content {
                    h3,
                    h4 {
                        //font-size: $big-titles;
                    }
                    .session__body {
                    }
                    .session__title {
                        .session__name {
                            //font-size: $big-titles;
                        }
                        .accordion-button {
                            &::after {
                                /*Chevron*/
                            }
                        }
                    }
                }
            }
        }
        .conference-agenda__secondary-nav {
            .react__search {
                span {
                    background-color: $main-color;
                    cursor: pointer;
                    transition: background-color 0.15s ease-in-out;
                    &:hover {
                        background-color: lighten($main-color, 20);
                    }
                }
            }
        }
        .body__session {
            .session__content {
                .speaker__info__job-title {
                    color: #333333;
                }
                .speaker__info__company {
                    //color: black;
                }
                .session__name,
                .body__speakers h3,
                .body__speakers h4 {
                    //font-weight: bold !important;
                }
            }
        }
    }
    &.speaker-list--TME {
        .speaker {
            &__name {
                color: $main-color;
            }
        }
    }
}
