h1,
h2,
h3,
h4,
h5 {
  font-family: motiva-sans, Helvetica, Arial;
}

p,
a,
li {
  font-family: Helvetica, Arial;
  line-height: 26px;
}

h1 {
  font-size: 60px;
  font-weight: 700;
  color: #333333;
}

h2 {
  font-size: 25px;
  font-weight: bold;
  color: #b18b2f;
}

h3 {
  font-size: 20px;
  font-weight: bold;
  color: #2f2621;
}

h4 {
  font-size: 18px;
  font-weight: bold;
  color: #2f2621;
}

p {
  font-size: 18px;
  color: #333333;
  margin-bottom: 10px;
  font-weight: 400;
}
p strong {
  font-weight: 700;
}

li {
  font-size: 18px;
  font-weight: 400;
  color: #333333;
}

a {
  color: #2f2621;
  text-decoration: none;
}
a:focus, a:hover {
  color: #4d3e36;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  p,
  a {
    overflow-wrap: break-word;
  }
}
body {
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 990px) {
  header.header--desktop .header__covid-info {
    top: 4px;
    position: absolute;
  }
  header.fixed-top {
    height: 50px;
    background: #fff;
    box-shadow: 0 3px 5px;
  }
  header.fixed-top .navbar {
    padding-bottom: 0;
    padding-top: 0;
  }
  header.fixed-top .navbar {
    padding-bottom: 0;
  }
  header.fixed-top .header__sub-menu,
  header.fixed-top .header__info,
  header.fixed-top .header__info--bonus,
  header.fixed-top .header__main-logo,
  header.fixed-top .header__covid-info {
    display: none !important;
  }
  header.fixed-top .header__main-menu {
    min-height: unset;
  }
  header.fixed-top .header__main-menu .header__locked-logo {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  header.fixed-top .header__main-menu nav {
    height: 50px;
  }
  header.header--default {
    border-top: 5px solid #b18b2f;
  }
  header.header--default .header__locked-logo {
    display: none;
  }
}
@media screen and (max-width: 990px) {
  header > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  header > .container-fluid .navbar-brand a {
    font-size: 20px;
    color: #fff;
    padding: 5px;
  }
  header nav .collapse .navbar-nav,
  header nav .collapsing .navbar-nav {
    justify-content: center;
    align-items: center;
  }
  header nav .collapse .navbar-nav .nav-item,
  header nav .collapsing .navbar-nav .nav-item {
    text-align: center;
    width: 100%;
    border-top: 1px solid gray;
  }
  header nav .collapse .navbar-nav .nav-item .nav-link,
  header nav .collapsing .navbar-nav .nav-item .nav-link {
    color: #fff;
    font-weight: normal;
  }
  header nav .collapse .navbar-nav .nav-item--reg--link,
  header nav .collapsing .navbar-nav .nav-item--reg--link {
    background-color: #b62f25;
  }
  header nav .collapse .navbar-nav .nav-item.nav-item--secondary-menu .dropdown-menu,
  header nav .collapsing .navbar-nav .nav-item.nav-item--secondary-menu .dropdown-menu {
    background-color: #595757;
    border-radius: 0px;
    text-align: center;
    margin-top: -1px;
    margin-bottom: 0;
  }
  header nav .collapse .navbar-nav .nav-item.nav-item--secondary-menu .dropdown-menu li + li,
  header nav .collapsing .navbar-nav .nav-item.nav-item--secondary-menu .dropdown-menu li + li {
    border-top: 1px solid gray;
  }
  header nav .collapse .navbar-nav .nav-item.nav-item--secondary-menu .dropdown-menu li a,
  header nav .collapsing .navbar-nav .nav-item.nav-item--secondary-menu .dropdown-menu li a {
    color: #fff;
  }
  header .header__details {
    margin-top: 56px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  header .header__details .header__main-logo {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  header .header__details__co-locate {
    max-width: 140px;
    padding-bottom: 10px;
  }
  header .header__details .header__info .header__info--location,
  header .header__details .header__info .header__info--date {
    display: block;
    text-align: center;
    margin: 0;
  }
}
header .header__sub-menu {
  height: 70px;
}
header .header__sub-menu .header__sub-menu__list {
  list-style-type: none;
  float: right;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}
header .header__sub-menu .header__sub-menu__list li {
  padding: 0 10px;
}
header .header__sub-menu .header__sub-menu__list a {
  text-decoration: none;
  font-weight: normal;
  font-size: 12px;
  color: #6a6768;
  -webkit-transition: color 0.2s linear;
}
header .header__sub-menu .header__sub-menu__list a:focus {
  color: #6a6768;
}
header .header__sub-menu .header__sub-menu__list a:hover {
  color: black;
}
header .header__sub-menu .header__sub-menu__list.active .header__search div input {
  outline: none !important;
  border: 1px solid #6a6768;
  border-left: none;
  border-right: none;
  width: 370px;
  opacity: 1 !important;
  font-size: 12px;
  display: block;
}
header .header__sub-menu .header__sub-menu__list.active .header__search div img:last-child {
  display: block !important;
}
header .header__sub-menu .header__sub-menu__list .header__search form > div {
  display: inline-flex;
  height: 100%;
}
header .header__sub-menu .header__sub-menu__list .header__search form > div input {
  opacity: 0;
  width: 0px;
  height: 30px;
  display: none;
}
header .header__sub-menu .header__sub-menu__list .header__search form > div img {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
header .header__sub-menu .header__sub-menu__list .header__search form > div img:last-child {
  display: none;
  border: 1px solid #6a6768;
}
header .header__sub-menu .header__sub-menu__co-locate {
  clear: both;
  float: right;
  margin-top: 5px;
}
@media (max-width: 1200px) {
  header .header__sub-menu .header__sub-menu__co-locate {
    margin-top: 0px;
  }
}
@media (min-width: 1400px) {
  header .header__sub-menu .header__sub-menu__co-locate {
    margin-right: 100px;
  }
}
header .header__sub-menu .header__sub-menu__co-locate > div {
  max-width: 150px;
  display: inline-block;
}
header .header__main-menu {
  min-height: 90px;
}
header .header__main-menu nav > div {
  padding: 0;
}
header .header__main-menu nav > div .navbar-nav .nav-item .active {
  color: #b18b2f !important;
  font-weight: bold !important;
}
header .header__main-menu nav > div .navbar-nav .nav-item .active::after {
  color: #333333 !important;
}
header .header__main-menu nav > div .navbar-nav .nav-item .nav-link {
  font-weight: 700;
  color: #333333;
  font-size: 14px;
  padding: 0px;
  -webkit-transition: color 0.2s linear;
  text-decoration: none;
}
@media (min-width: 990px) and (max-width: 1200px) {
  header .header__main-menu nav > div .navbar-nav .nav-item .nav-link {
    font-size: 12px;
  }
}
header .header__main-menu nav > div .navbar-nav .nav-item .nav-link:hover {
  color: gray;
}
header .header__main-menu nav > div .navbar-nav .nav-item .nav-link::after {
  content: "|";
  text-decoration: none;
  padding: 0px 8px 0px 8px;
}
@media (min-width: 990px) and (max-width: 1200px) {
  header .header__main-menu nav > div .navbar-nav .nav-item .nav-link::after {
    padding: 0px 4px 0px 4px;
  }
}
header .header__main-menu nav > div .navbar-nav .nav-item:nth-last-child(2) .nav-link::after {
  content: "";
}
header .header__main-menu nav > div .navbar-nav .nav-item.nav-item--reg-link {
  margin-top: -5px;
  background-color: #b62f25;
  border-radius: 5px;
  padding: 0 7px;
  transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
}
header .header__main-menu nav > div .navbar-nav .nav-item.nav-item--reg-link .nav-link {
  text-decoration: none;
  padding: 3px 0px 0px 0px;
  font-weight: bold;
  color: #fff;
}
header .header__main-menu nav > div .navbar-nav .nav-item.nav-item--reg-link:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 1.5px #b62f25;
}
header .header__main-menu nav > div .navbar-nav .nav-item.nav-item--reg-link:hover .nav-link {
  color: #b62f25;
}
header .header__main-menu nav > div .navbar-nav .nav-item:first-child a {
  padding-left: 0;
}
header .header__main-menu nav > div .navbar-nav .nav-item:last-child .nav-link:after {
  content: "";
  padding: 0;
}
header .header__main-menu .header__main-logo {
  display: flex;
  justify-content: center;
  height: 100%;
}
header .header__main-menu .header__main-logo img {
  width: 100%;
}
header .header__info {
  margin: 0;
}
header .header__info--date, header .header__info--location {
  display: inline-block;
  font-size: 17px;
}
header .header__info--date {
  color: #333333;
  font-weight: bold;
  margin-right: 20px;
}
header .header__info--location {
  color: #333333;
  font-weight: bold;
  text-align: center;
}
header .header__info h2 {
  margin-bottom: 0;
}
header .header__info--bonus {
  margin: 0;
}
header .header__info--bonus p {
  font-size: 16px;
  margin: 0;
}
header .header__info--bonus p span {
  color: #b18b2f;
  font-weight: bold;
}

@media screen and (min-width: 990px) {
  header#new.header--desktop .header__covid-info {
    top: 4px;
    position: absolute;
  }
  header#new.fixed-top {
    height: 31px;
    background: #fff;
    box-shadow: 0 3px 5px;
  }
  header#new.fixed-top .navbar {
    padding-bottom: 0;
    padding-top: 0;
  }
  header#new.fixed-top .header__sub-menu,
  header#new.fixed-top .header__info,
  header#new.fixed-top .header__info--bonus,
  header#new.fixed-top .header__main-logo,
  header#new.fixed-top .header__covid-info {
    display: none !important;
  }
  header#new.fixed-top .header__main-menu {
    min-height: unset;
  }
  header#new.fixed-top .header__main-menu .header__locked-logo {
    display: flex;
    height: 50px;
    justify-content: center;
    align-items: center;
  }
  header#new.fixed-top .header__main-menu nav {
    height: 31px;
  }
  header#new.header--default {
    border-top: 5px solid #b18b2f;
  }
  header#new.header--default .header__main-menu {
    margin-top: 0px;
  }
  header#new.header--default .header__locked-logo {
    display: none;
  }
}
@media screen and (max-width: 990px) {
  header#new > .container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
  header#new > .container-fluid .navbar-brand a {
    font-size: 20px;
    color: #fff;
    padding: 5px;
  }
  header#new .navbar--tme-onepage {
    height: 56px;
  }
  header#new nav .collapse .navbar-nav,
  header#new nav .collapsing .navbar-nav {
    justify-content: flex-start;
    align-items: center;
  }
  header#new nav .collapse .navbar-nav li,
  header#new nav .collapsing .navbar-nav li {
    text-align: center;
    width: 100%;
    border-top: 1px solid gray;
    text-transform: uppercase;
  }
  header#new nav .collapse .navbar-nav li .nav-link,
  header#new nav .collapsing .navbar-nav li .nav-link {
    color: #fff;
    font-weight: normal;
  }
  header#new nav .collapse .navbar-nav li.nav-item--secondary-menu .dropdown-menu,
  header#new nav .collapsing .navbar-nav li.nav-item--secondary-menu .dropdown-menu {
    background-color: #595757;
    border-radius: 0px;
    text-align: center;
    margin-top: -1px;
    margin-bottom: 0;
  }
  header#new nav .collapse .navbar-nav li.nav-item--secondary-menu .dropdown-menu li + li,
  header#new nav .collapsing .navbar-nav li.nav-item--secondary-menu .dropdown-menu li + li {
    border-top: 1px solid gray;
  }
  header#new nav .collapse .navbar-nav li.nav-item--secondary-menu .dropdown-menu li a,
  header#new nav .collapsing .navbar-nav li.nav-item--secondary-menu .dropdown-menu li a {
    color: #fff;
  }
  header#new nav .collapse .navbar-nav ul.submenu,
  header#new nav .collapsing .navbar-nav ul.submenu {
    display: none;
    padding: 0;
  }
  header#new nav .collapse .navbar-nav ul.submenu li,
  header#new nav .collapsing .navbar-nav ul.submenu li {
    text-transform: capitalize;
    background-color: #595757;
  }
  header#new nav .collapse .navbar-nav li.active ul.submenu,
  header#new nav .collapsing .navbar-nav li.active ul.submenu {
    display: block;
  }
  header#new nav .collapse .navbar-nav li.active ul.submenu li.active a,
  header#new nav .collapsing .navbar-nav li.active ul.submenu li.active a {
    border-left: 2px solid #b18b2f;
    border-right: 2px solid #b18b2f;
  }
  header#new .header__details {
    margin-top: 56px;
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media screen and (max-width: 990px) and (max-width: 990px) {
  header#new .header__details {
    margin-top: 0px;
    padding-top: 0px;
  }
}
@media screen and (max-width: 990px) {
  header#new .header__details .header__main-logo {
    padding-top: 110px;
    padding-bottom: 10px;
  }
  header#new .header__details .header__info .header__info--location,
  header#new .header__details .header__info .header__info--date {
    display: block;
    text-align: center;
    margin: 0;
  }
  header#new .header__details .header__info .header__info--location {
    margin-bottom: 5px;
  }
  header#new .header__details .header__info--bonus {
    text-align: center;
  }
}
header#new .header__main-logo {
  position: relative;
  top: 5px;
}
@media screen and (max-width: 990px) {
  header#new .header__main-logo {
    padding: 10px;
  }
}
header#new .header__main-logo img {
  width: 100%;
}
header#new .header__main-logo.co-located-logo {
  position: absolute;
  top: unset;
  top: auto;
}
header#new .header__main-logo.co-located-logo img {
  width: 80%;
}
header#new .header__sub-menu {
  height: 50px;
}
header#new .header__sub-menu .header__sub-menu__list {
  list-style-type: none;
  float: right;
  display: flex;
  padding-top: 3px;
  justify-content: space-between;
  margin-bottom: 0;
  padding-right: 145px;
}
@media (min-width: 990px) and (max-width: 1200px) {
  header#new .header__sub-menu .header__sub-menu__list {
    padding-top: 2px;
  }
}
@media only screen and (min-width: 1441px) {
  header#new .header__sub-menu .header__sub-menu__list {
    padding-right: 246px;
  }
}
header#new .header__sub-menu .header__sub-menu__list a {
  text-decoration: none;
  font-weight: normal;
  font-size: 15px;
  color: #6a6768;
  -webkit-transition: color 0.2s linear;
}
header#new .header__sub-menu .header__sub-menu__list a:focus {
  color: #6a6768;
}
header#new .header__sub-menu .header__sub-menu__list a:hover {
  color: black;
}
header#new .header__sub-menu .header__sub-menu__list.active .header__search div input {
  outline: none !important;
  border: 1px solid #6a6768;
  border-left: none;
  border-right: none;
  width: 370px;
  opacity: 1 !important;
  font-size: 12px;
  display: block;
}
header#new .header__sub-menu .header__sub-menu__list.active .header__search div img:last-child {
  display: block !important;
}
header#new .header__sub-menu .header__sub-menu__list .header__search form > div {
  display: inline-flex;
  height: 100%;
}
header#new .header__sub-menu .header__sub-menu__list .header__search form > div input {
  opacity: 0;
  width: 0px;
  height: 30px;
  display: none;
}
header#new .header__sub-menu .header__sub-menu__list .header__search form > div img {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
header#new .header__sub-menu .header__sub-menu__list .header__search form > div img:last-child {
  display: none;
  border: 1px solid #6a6768;
}
header#new .header__sub-menu .header__sub-menu__co-locate {
  clear: both;
  float: right;
  margin-top: 25px;
}
@media (max-width: 1200px) {
  header#new .header__sub-menu .header__sub-menu__co-locate {
    display: none;
  }
}
@media (min-width: 1200px) {
  header#new .header__sub-menu .header__sub-menu__co-locate {
    margin-right: 0px;
  }
}
@media (min-width: 1400px) {
  header#new .header__sub-menu .header__sub-menu__co-locate {
    margin-right: 100px;
  }
}
header#new .header__sub-menu .header__sub-menu__co-locate > div {
  max-width: 150px;
  display: inline-block;
}
header#new .header__main-menu {
  min-height: 0px;
  /*nav {
      & > div {
          padding: 0;
          .navbar-nav {
              //justify-content:center;
              .nav-item {
                  //padding-left:0!important;padding-right:0!important; 
                  .active {

                      font-weight: 1000 !important;
                       color: #ffffff!important ;
                       font-weight: 1000 !important;
                       border:1px solid red;
                       background:black;
                       padding: 5px 10px 5px 10px!important;
                      &::after {
                         // color: #333333 !important;
                      }
                  }
                  .nav-link {
                      //here
                      color: red;
                     -webkit-transition: color 0.2s linear;
                      text-decoration: none;
                       font-size: 13px;
                       padding-top: 10px;
                       padding:5px 10px 0 10px!important;
                       font-weight:bold;
                       display:flex;
                        //.bi.bi-house-fill{
                           //font-size:19px;
                      //}
                      @media (min-width: 990px) and (max-width: 1200px) {
                          font-size: 12px;
                      }

                      &:hover {
                         // color:#fff;
                        //  background:black;
                          //color: lighten($color: #333333, $amount: 30%);

                      }
                      &::after {
                       //   content: "";
                         // color: $main-color !important;
                          //font-weight: normal;
                          //text-decoration: none;
                          //padding: 0px ;
                          @media (min-width: 990px) and (max-width: 1200px) {
                              padding: 0px 4px 0px 4px;
                          }
                      }
                  }
                  //SET SUBMENU BAR NO DISPLAY BY DEFAULT
                   //ul.submenu {display: none;}
                  //ACTIVATE SUBMENU IF THE TAB HAS CHILDREN AND HAS CLASS ACTIVE ()
                   .nav-link.active + .submenu{
                       /*   display: block;
                          position: absolute;
                          z-index: 1;
                          background: white;
                          width: 100%;
                          left: 0;
                          right: 0;
                          display:flex;
                          list-style:none;
                          justify-content:center;
                          background: black;
                          padding:0;
                   }
                  .nav-link{
                      //submenu part

                      &.active + .submenu {
                          .nav-item-sub{
                              // padding :0 10px;
                                  //  font-size:13px;
                                    .nav-link-sub{
                                  //  vertical-align:text-top;
                                    //    text-transform:uppercase;
                                     //   color:white!important;
                                      //  text-decoration:none;
                                   // &:hover{color:$main-color!important;}
                                    }

                          }

                      }
                  }

                  &.nav-item--reg-link {
                      //margin-top: -5px;
                      //background-color: $reg-color;
                      //border-radius: 5px;
                     // padding: 0 7px;
                      // margin-top:0;
                     // background-color: transparent;
                      //border-radius: 5px;
                    // padding: 0 ;
                     //color:$main-color;
                    //  transition: background 0.2s linear;
                      //-webkit-transition: background 0.2s linear;
                      //transition: color 0.2s linear;
                      //-webkit-transition: color 0.2s linear;
                      .nav-link {
                         // text-decoration: none;
                         // padding: 4px 0px 0px 0px;
                         // font-weight: 1000;
                        //  color: $reg-color;
                      }
                      //anim
                      &:hover {
                         // background-color: #fff;
                         //background-color: transparent;
                         background:black;
                         border-radius:0;
                          box-shadow: inset 0 0 0 1.5px transparent;
                          .nav-link {
                              color: $reg-color;
                          }
                      }
                  }

                  &:first-child {
                      a {
                          padding-left: 0;
                      }
                  }
                  &:last-child {
                      .nav-link {
                          &:after {
                              content: "";
                              padding: 0;
                          }
                      }
                  }
              }
          }
      }
  }*/
}
header#new .header__main-menu .collapse.navbar-collapse {
  justify-content: center;
}
header#new .header__main-menu .navbar {
  padding: 0;
  justify-content: center;
}
header#new .header__main-menu .navbar-nav {
  justify-content: flex-start;
  align-items: center;
  padding: 0;
}
header#new .header__main-menu .navbar-nav li a.nav-link {
  text-transform: uppercase;
  -webkit-transition: color 0.2s linear;
  text-decoration: none;
  font-size: 13px;
  padding: 0px 10px 0 10px !important;
  font-weight: bold;
  display: flex;
}
header#new .header__main-menu .navbar-nav li ul {
  display: none;
}
header#new .header__main-menu .navbar-nav li a {
  color: black;
}
header#new .header__main-menu .navbar-nav li a .bi.bi-house-fill {
  font-size: 19px;
}
header#new .header__main-menu .navbar-nav li:hover {
  background: black;
  border-radius: 0;
  box-shadow: inset 0 0 0 1.5px transparent;
}
header#new .header__main-menu .navbar-nav li:hover a {
  color: white;
}
header#new .header__main-menu .navbar-nav li.active {
  color: #ad2d20;
  background: black;
  font-weight: 1000 !important;
  color: #ffffff !important;
}
header#new .header__main-menu .navbar-nav li.active::after {
  color: #333333 !important;
}
header#new .header__main-menu .navbar-nav li.active a {
  color: #fff;
}
header#new .header__main-menu .navbar-nav li.active ul {
  display: block;
  position: absolute;
  z-index: 1;
  background: white;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  list-style: none;
  justify-content: center;
  background: black;
  padding: 0;
}
header#new .header__main-menu .navbar-nav li.active ul li {
  padding: 0px !important;
  font-size: 13px;
}
header#new .header__main-menu .navbar-nav li.active ul li.active {
  padding: 0 !important;
}
header#new .header__main-menu .navbar-nav li.active ul li.active > a {
  color: #b18b2f;
}
header#new .header__main-menu .navbar-nav li.active ul li a {
  vertical-align: text-top;
  text-transform: uppercase;
  color: white;
  text-decoration: none;
}
header#new .header__main-menu .navbar-nav li.active ul li a:hover {
  color: #b18b2f !important;
}
header#new .header__main-menu .navbar-nav li.last {
  margin-top: 0;
  padding: 0;
}
header#new .header__main-menu .navbar-nav li.last a {
  color: #b18b2f;
  transition: background 0.2s linear;
  -webkit-transition: background 0.2s linear;
  transition: color 0.2s linear;
  -webkit-transition: color 0.2s linear;
}
header#new .header__main-menu .header__main-logo {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;
}
header#new .header__main-menu .header__main-logo img {
  width: 100%;
}
header#new .header__main-menu .header__main-logo.co-located-logo {
  position: absolute;
}
@media screen and (max-width: 990px) {
  header#new .header__info {
    margin-top: 35px;
  }
}
header#new .header__info--date, header#new .header__info--location {
  display: block;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
header#new .header__info--date {
  margin: 0 20px 0 0;
}
header#new .header__info--location {
  color: #333333;
}
header#new .header__info--bonus {
  margin: 0;
  text-align: left;
}
header#new .header__info--bonus p {
  font-size: 16px;
  margin: 0;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none;
}

.container.google-translate-container {
  position: relative;
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .container.google-translate-container {
    top: 12px;
    left: 0px;
    right: 0px;
  }
}

#google_translate_element {
  height: 33px;
  overflow: hidden;
  position: absolute;
  z-index: 99999;
  right: 0px;
}
@media only screen and (max-width: 767px) {
  #google_translate_element {
    height: 20px;
    top: 63px;
    text-align: right;
    z-index: auto;
    margin-right: 12px;
  }
}
@media only screen and (min-width: 1441px) {
  #google_translate_element {
    right: 105px;
  }
}

.goog-te-gadget .goog-te-combo {
  margin: 11px 10px !important;
  color: #6a6768;
  outline: none;
}
@media only screen and (max-width: 767px) {
  .goog-te-gadget .goog-te-combo {
    margin: 0 !important;
  }
}

body {
  top: 0 !important;
}

footer {
  background-color: #262626;
  padding-bottom: 20px;
}
footer a:hover {
  text-decoration: underline !important;
}
footer > .container {
  padding-top: 20px;
}
footer .footer__sitemap a {
  color: #fff;
  text-transform: capitalize;
  font-weight: 200;
  text-decoration: none;
  font-size: 12px;
}
footer .footer__sitemap a:hover {
  text-decoration: underline;
}
footer .footer__sitemap .sitemap__column .sitemap__column__top > a {
  font-size: 18px;
  padding-bottom: 3px;
}
footer .footer__all-shows {
  padding-top: 10px;
}
@media (max-width: 768px) {
  footer .footer__all-shows {
    display: none;
  }
}
footer .footer__all-shows .all-shows__message small {
  color: #fff;
}
footer .footer__all-shows .all-shows__show-logo {
  height: 70px;
  line-height: 62px;
  text-align: center;
  padding: 0 5px;
}
footer .footer__all-shows .all-shows__show-logo img {
  -webkit-filter: grayscale(100%);
  -webkit-transition: filter 0.3s linear;
  display: inline-block;
  max-height: 50px;
  height: auto;
  max-width: 100%;
}
footer .footer__all-shows .all-shows__show-logo img:hover {
  -webkit-filter: none;
}
footer .footer__disclaimers {
  padding-top: 20px;
}
@media (max-width: 768px) {
  footer .footer__disclaimers > div {
    padding: 10px;
  }
}
footer .footer__disclaimers > div {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .footer__disclaimers > div img {
  margin: auto;
  display: block;
  max-width: 100%;
  height: auto;
}
footer .footer__disclaimers small,
footer .footer__disclaimers a {
  color: #fff;
}
footer .footer__disclaimers a {
  text-decoration: none;
}
footer .footer__terms {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding: 0 6%;
}
@media (max-width: 992px) {
  footer .footer__terms {
    padding: 0;
    text-align: center;
  }
  footer .footer__terms div {
    margin: auto;
  }
}
footer .footer__terms div {
  width: auto;
}
footer .footer__terms small,
footer .footer__terms a {
  color: #fff;
}
footer .footer__terms a {
  text-decoration: none;
}

.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__side-flag {
  height: 150px;
  width: 10px;
  position: absolute;
}
@media (max-width: 768px) {
  .enhanced-exhibitors .exhibitor-slide .exhibitor-slide__side-flag {
    height: 260px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .enhanced-exhibitors .exhibitor-slide .exhibitor-slide__side-flag {
    height: 200px;
  }
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__hr hr {
  margin-top: 0px;
  margin-bottom: 0px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__logo div {
  height: 100%;
  margin: 0;
  display: flex;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__logo div {
  align-items: center;
  justify-content: center;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__logo div img {
  cursor: pointer;
  max-width: 100%;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__logo div img:hover {
  opacity: 0.9;
}
@media (min-width: 768px) {
  .enhanced-exhibitors .exhibitor-slide .exhibitor-slide__logo div img {
    max-height: 150px;
  }
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__body h4 {
  cursor: pointer;
  margin-bottom: 5px;
  font-weight: bold;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__body h4 a {
  color: inherit;
  text-decoration: none;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__body h4:hover {
  opacity: 0.9;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__cats ul {
  padding-left: 0px;
  list-style-type: none;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__cats ul li {
  font-size: 12px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__cats ul li::after {
  content: "|";
  padding-right: 5px;
  padding-left: 5px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__cats ul li:last-child::after {
  content: "";
}
@media screen and (max-width: 990px) and (min-width: 768px) {
  .enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__logo,
  .enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__body,
  .enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__cats {
    height: 200px !important;
  }
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__logo {
  height: 150px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__logo div {
  padding: 5px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__body {
  height: 150px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__body div {
  flex-direction: column;
  justify-content: center;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__body div {
  height: 100%;
  margin: 0;
  display: flex;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__cats {
  height: 150px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__cats li {
  display: inline;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__cats div {
  padding: 10px;
  align-items: center;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__desk .exhibitor-slide__cats div {
  height: 100%;
  margin: 0;
  display: flex;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__mob .exhibitor-slide__body {
  height: 260px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__mob .exhibitor-slide__body > div {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__mob .exhibitor-slide__body p {
  margin-bottom: 3px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__mob .exhibitor-slide__logo {
  height: 260px;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__mob .exhibitor-slide__cats li {
  display: inline-block;
}

.spinner-border {
  display: inline-block;
  width: 10rem;
  height: 10rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.enhanced-exhibitors__cta {
  background-color: black;
  border: none;
  color: #fff;
  padding: 5px 10px 5px 10px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}
.enhanced-exhibitors__cta a {
  text-decoration: none;
  color: unset;
}
.enhanced-exhibitors__cta a:focus, .enhanced-exhibitors__cta a:hover {
  color: unset;
  text-decoration: none;
}
.enhanced-exhibitors__cta:hover {
  opacity: 0.8;
}
.enhanced-exhibitors__cta:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.enhanced-exhibitors__cta--enhanced-listing, .enhanced-exhibitors__cta--premium-listing {
  position: absolute;
  right: 0;
  margin-top: -1px;
  z-index: 99;
  padding: 0;
}
.enhanced-exhibitors__cta--enhanced-listing > span, .enhanced-exhibitors__cta--premium-listing > span {
  display: inline-block;
}
.enhanced-exhibitors__cta--enhanced-listing > span:first-child, .enhanced-exhibitors__cta--premium-listing > span:first-child {
  background-color: black;
  padding: 5px 10px 5px 10px;
}
.enhanced-exhibitors__cta--enhanced-listing > span:last-child, .enhanced-exhibitors__cta--premium-listing > span:last-child {
  padding: 5px 10px 5px 10px;
  background-color: #5a5a5a;
}
.enhanced-exhibitors__cta--enhanced-listing > span:last-child span + span, .enhanced-exhibitors__cta--premium-listing > span:last-child span + span {
  padding-left: 10px;
}
.enhanced-exhibitors__cta--premium-listing {
  background-color: gold;
}
.enhanced-exhibitors__cta--reset {
  right: 15px;
  position: absolute;
}
.enhanced-exhibitors__cta--max {
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-size: 2rem;
  font-weight: bold;
}
.enhanced-exhibitors__cta--reg {
  background-color: #a92e25;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta {
  margin-bottom: 5px;
  color: initial;
  font-size: 1.5rem;
  padding: 10px 10px 10px 10px;
  border-left: 10px solid black;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta.inactive {
  opacity: 0.3;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta div {
  height: 100%;
  display: flex;
  align-items: center;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta div .exhibitors__cta--checkbox-text {
  display: block;
  float: left;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta div .exhibitors__cta--checkbox-cta__checkbox {
  right: 25px;
  position: absolute;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta div .exhibitors__cta--checkbox-cta__checkbox img {
  display: block;
  width: 15px;
  height: 15px;
}
.enhanced-exhibitors__cta.exhibitors__cta--checkbox-cta:last-child {
  margin-bottom: 10px;
}

.main-menu .affix .enhanced-exhibitors__cta--reset {
  right: 10px;
}
@media (max-width: 768px) {
  .main-menu .affix {
    top: 35px;
    width: 100%;
    z-index: 999;
    background: #fff;
    opacity: 1;
    left: 0;
    right: 0;
    padding-left: 10px;
    padding-right: 10px;
    box-shadow: 0px 3px 5px;
  }
}
@media (min-width: 768px) {
  .main-menu .affix {
    top: 60px;
  }
}
@media (min-width: 1200px) {
  .main-menu .affix {
    width: 28%;
    max-width: 350px;
    top: 60px;
  }
}
.main-menu .main-menu__title div {
  height: 20px;
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.main-menu .main-menu__title div h4 {
  position: absolute;
}
@media (max-width: 768px) {
  .main-menu .main-menu__ctas .main-menu__expo-pickers div:first-child {
    padding-left: 0;
    padding-right: 5px;
  }
  .main-menu .main-menu__ctas .main-menu__expo-pickers div:last-child {
    padding-right: 0;
    padding-left: 5px;
  }
}
@media (min-width: 768px) {
  .main-menu .main-menu__ctas .main-menu__expo-pickers div {
    position: initial;
    width: initial;
    float: initial;
    padding: 0;
  }
}
.main-menu hr {
  border-color: gainsboro;
  margin-top: 10px;
  margin-bottom: 10px;
}

.enhanced-exhibitors__alphabet > div {
  border-bottom: 1px solid black;
}
.enhanced-exhibitors__alphabet > div > div {
  margin-top: 10px;
  height: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.enhanced-exhibitors__alphabet > div > div span {
  cursor: pointer;
  padding-right: 10px;
}
.enhanced-exhibitors__alphabet > div > div + hr {
  margin-top: 0;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .enhanced-exhibitors__alphabet > div > div {
    height: 70px;
    justify-content: center;
  }
  .enhanced-exhibitors__alphabet > div > div span {
    padding-right: 5px;
  }
}
@media screen and (min-width: 768px) and (max-width: 990px) {
  .enhanced-exhibitors__alphabet > div > div {
    height: 50px;
    justify-content: center;
  }
}

@media (min-width: 1000px) and (max-width: 1200px) {
  .enhanced-exhibitors--CEX .enhanced-exhibitors__alphabet div > div {
    line-height: 10px;
  }
}
.enhanced-exhibitors--CEX .enhanced-exhibitors__cta--premium-listing {
  background-color: #b88e14;
}
.enhanced-exhibitors--CEX .exhibitor-slide--Premium .exhibitor-slide__body h4 {
  color: #b88e14;
}
.enhanced-exhibitors--CEX .exhibitor-slide__cats li {
  color: #5c5c5c;
}
@media (min-width: 768px) and (max-width: 900px) {
  .enhanced-exhibitors--CEX .main-menu .affix {
    width: 30%;
  }
}
@media (min-width: 900px) and (max-width: 1090px) {
  .enhanced-exhibitors--CEX .main-menu .affix {
    width: 25%;
  }
}
@media (min-width: 1090px) and (max-width: 1200px) {
  .enhanced-exhibitors--CEX .main-menu .affix {
    width: 22%;
  }
}
.enhanced-exhibitors--CEX .main-menu .enhanced-exhibitors__cta {
  border-radius: 10px;
}
.enhanced-exhibitors--CEX .main-menu .affix-top .main-menu__ctas__cerion-banner {
  display: none;
}
@media (max-width: 768px) {
  .enhanced-exhibitors--CEX .main-menu .main-menu__ctas__cerion-banner {
    display: none;
  }
}
.enhanced-exhibitors--CEX .main-menu .main-menu__ctas__cerion-banner img {
  max-width: 100%;
  border: 1px solid black;
}

.enhanced-exhibitors {
  margin-top: 20px;
  /**
  	RETROFITS FOR THIS VERSION TO WORK IN BOOTSTRAP 5
  */
}
.enhanced-exhibitors .featured-list,
.enhanced-exhibitors .az-list {
  min-height: 500px;
}
.enhanced-exhibitors .featured-list .exhibitor-slide:first-child .exhibitor-slide__hr,
.enhanced-exhibitors .az-list .exhibitor-slide:first-child .exhibitor-slide__hr {
  margin-top: 0px;
}
.enhanced-exhibitors .featured-list .exhibitor-slide:first-child .exhibitor-slide__hr hr,
.enhanced-exhibitors .az-list .exhibitor-slide:first-child .exhibitor-slide__hr hr {
  border-top: 1px solid black;
}
.enhanced-exhibitors .featured-list .exhibitor-slide__hr hr,
.enhanced-exhibitors .az-list .exhibitor-slide__hr hr {
  border-top: 1px solid black;
}
.enhanced-exhibitors .exhibitor-slide__mob {
  padding-top: 25px;
  padding-bottom: 10px;
}
.enhanced-exhibitors .exhibitor-slide__mob .row > div {
  height: unset !important;
}
.enhanced-exhibitors .main-menu .enhanced-exhibitors__cta--max {
  font-size: 1rem;
}
@media (min-width: 768px) {
  .enhanced-exhibitors .main-menu .main-menu--default,
  .enhanced-exhibitors .main-menu .main-menu--fixed-left {
    max-width: 356px;
  }
}
@media (min-width: 1200px) {
  .enhanced-exhibitors .main-menu .main-menu--default,
  .enhanced-exhibitors .main-menu .main-menu--fixed-left {
    width: 356px;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .enhanced-exhibitors .main-menu .main-menu--default h4,
  .enhanced-exhibitors .main-menu .main-menu--fixed-left h4 {
    font-size: 16px;
  }
}
.enhanced-exhibitors .main-menu .main-menu--fixed-left {
  position: fixed;
  top: 50px;
}
@media (min-width: 990px) and (max-width: 1200px) {
  .enhanced-exhibitors .main-menu .main-menu--fixed-left {
    width: 300px;
  }
}
.enhanced-exhibitors .main-menu .main-menu--fixed-top {
  position: fixed;
  z-index: 999;
  top: 58px;
  background-color: #fff;
  left: 0;
  right: 0;
  padding-left: 15px;
  padding-right: 15px;
}
.enhanced-exhibitors .main-menu .main-menu--fixed-top button {
  font-size: 16px;
}
.enhanced-exhibitors .main-menu__title > div {
  position: relative;
}
.enhanced-exhibitors .main-menu__title > div h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}
.enhanced-exhibitors .main-menu__title .enhanced-exhibitors__cta--reset {
  right: 0;
}
.enhanced-exhibitors hr {
  opacity: 1;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__hr {
  position: relative;
}
.enhanced-exhibitors .exhibitor-slide .exhibitor-slide__hr hr {
  height: 1px;
}
.enhanced-exhibitors .section_header {
  margin-top: 20px;
  margin-bottom: 10px;
}
.enhanced-exhibitors .enhanced-exhibitors__alphabet .col-sm-12 {
  border-bottom: none;
}
.enhanced-exhibitors .enhanced-exhibitors__alphabet .col-sm-12 > div {
  border-bottom: 1px solid black;
}
@media (max-width: 576px) {
  .enhanced-exhibitors .hidden-xs {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .enhanced-exhibitors .visible-xs {
    display: block !important;
  }
}
@media (min-width: 1400px) {
  .enhanced-exhibitors .hidden-xxl {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .enhanced-exhibitors .visible-xxl {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .enhanced-exhibitors .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .enhanced-exhibitors .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .enhanced-exhibitors .hidden-md {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .enhanced-exhibitors .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .enhanced-exhibitors .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .enhanced-exhibitors .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .enhanced-exhibitors .hidden-xl {
    display: none !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .enhanced-exhibitors .visible-xl {
    display: block !important;
  }
}

.exhibitor-drilldown__cta {
  background-color: black;
  border: none;
  color: #fff;
  padding: 5px 10px 5px 10px;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}
.exhibitor-drilldown__cta:hover {
  opacity: 0.8;
}
.exhibitor-drilldown__cta:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.exhibitor-drilldown__cta--enhanced-listing, .exhibitor-drilldown__cta--premium-listing {
  position: absolute;
  right: 0;
  margin-top: -1px;
  z-index: 99;
  cursor: default;
}
.exhibitor-drilldown__cta--enhanced-listing:hover, .exhibitor-drilldown__cta--premium-listing:hover {
  opacity: 1;
}
.exhibitor-drilldown__cta--enhanced-listing:focus, .exhibitor-drilldown__cta--premium-listing:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.exhibitor-drilldown__cta--premium-listing {
  background-color: gold;
}
.exhibitor-drilldown__cta--reset {
  right: 15px;
  position: absolute;
}
.exhibitor-drilldown__cta--max {
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-size: 2rem;
  font-weight: bold;
}
.exhibitor-drilldown__cta--micro {
  font-size: 1rem;
  font-weight: normal;
}
.exhibitor-drilldown__cta--reg {
  background-color: #a92e25;
}

.exhibitor-drilldown__horizontal {
  border-top: 1px solid black;
}
.exhibitor-drilldown__horizontal h4,
.exhibitor-drilldown__horizontal h5 {
  font-weight: bold;
}
@media (min-width: 768px) {
  .exhibitor-drilldown__horizontal h4 {
    padding: 10px 0px 10px 0px;
  }
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable {
  height: auto;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable {
    height: auto;
  }
  .exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div {
    padding: 5px 0px 5px 0px;
  }
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0px 15px 0px;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div p {
  text-align: center;
  margin-top: 25px;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div > div {
  height: 195px;
  text-align: center;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div > div a .thumb {
  max-width: 100%;
  height: 100%;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div > div .fi {
  margin: auto;
  margin-top: -50px;
  margin-right: -10px;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__downloadable > div > div .fi .fi-content {
  padding-top: 0px;
  writing-mode: vertical-rl;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__video {
  height: auto;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__horizontal .exhibitor-drilldown__video {
    margin-bottom: 20px;
    height: auto;
  }
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__video > div {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.exhibitor-drilldown__horizontal .exhibitor-drilldown__video p {
  text-align: center;
  font-weight: normal;
}

.exhibitor-drilldown__vertical {
  border-top: 1px solid black;
}
.exhibitor-drilldown__vertical h4,
.exhibitor-drilldown__vertical h5 {
  font-weight: bold;
}
@media (min-width: 768px) {
  .exhibitor-drilldown__vertical h4 {
    margin: 15px 0px 15px 0px;
  }
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .mob {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__vertical .exhibitor-drilldown__news .mob .news__image {
    width: auto;
    float: left;
    padding-right: 10px;
  }
  .exhibitor-drilldown__vertical .exhibitor-drilldown__news .mob .news__image img {
    cursor: pointer;
    width: 150px;
    padding: 5px;
    max-width: 100%;
    max-height: 100%;
  }
}
@media (min-width: 768px) and (max-width: 1200px) {
  .exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body {
    height: auto !important;
    margin-bottom: 20px;
  }
  .exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__image {
    height: auto !important;
  }
  .exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__image div img {
    max-height: 115px;
  }
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__image {
  height: 200px;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__image > div {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__image img {
  cursor: pointer;
  max-height: 160px;
  width: auto;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__image img:hover {
  opacity: 0.8;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body {
  height: 200px;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body > div {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body h5 {
  margin-top: 0px;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body a {
  cursor: pointer;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body a:hover {
  opacity: 0.8;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body .news__copy span {
  color: #23527c;
  cursor: pointer;
}
.exhibitor-drilldown__vertical .exhibitor-drilldown__news .desk .news__body .news__copy span:hover {
  text-decoration: underline;
}

.exhibitor-drilldown__bio {
  min-height: 200px;
  border-top: 1px solid black;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__bio {
    display: none;
  }
}
.exhibitor-drilldown__bio > div {
  padding: 20px 0px 10px 0px;
}
.exhibitor-drilldown__bio .logo {
  width: auto;
  float: left;
  margin-top: -10px;
}
@media (min-width: 768px) {
  .exhibitor-drilldown__bio .logo {
    margin-right: 30px;
  }
}
.exhibitor-drilldown__bio .logo img {
  padding: 20px;
  max-width: 300px;
  max-height: 300px;
  min-width: 200px;
}
@media (min-width: 768px) {
  .exhibitor-drilldown__bio .body {
    margin-top: 5px;
  }
}
.exhibitor-drilldown__bio .body .short-bio {
  font-weight: bold;
}
.exhibitor-drilldown__bio .body .cats {
  margin-top: 10px;
}
.exhibitor-drilldown__bio .body .cats span {
  font-weight: bold;
}

.exhibitor-drilldown__meta h4 {
  font-weight: bold;
}
.exhibitor-drilldown__meta .affix {
  top: 60px;
}
.exhibitor-drilldown__meta > div {
  padding-left: 20px;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__meta > div {
    padding-left: 0;
  }
}
.exhibitor-drilldown__meta .main-cta {
  padding: 20px 0px 20px 0px;
}
.exhibitor-drilldown__meta .booth {
  border-top: 1px solid black;
  padding: 5px 0px 5px 0px;
}
.exhibitor-drilldown__meta .meta-data {
  border-top: 1px solid black;
  padding: 5px 0px 5px 0px;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__meta .meta-data div {
    margin-top: 30px;
  }
}
.exhibitor-drilldown__meta .meta-data p {
  cursor: pointer;
  font-weight: bold;
}
.exhibitor-drilldown__meta .meta-data .address {
  font-weight: normal;
  cursor: text;
}
.exhibitor-drilldown__meta .meta-data .address span {
  font-weight: bold;
}
.exhibitor-drilldown__meta .meta-data .address br + span {
  padding-left: 25px;
  display: inline-block;
}
.exhibitor-drilldown__meta .message {
  border-top: 1px solid black;
  padding: 10px 0px 10px 0px;
}
.exhibitor-drilldown__meta .message p {
  font-weight: bold;
}
.exhibitor-drilldown__meta .glyphicon {
  padding-right: 5px;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__meta .message {
    display: none;
  }
  .exhibitor-drilldown__meta .mob-lower {
    margin-top: 20px;
  }
  .exhibitor-drilldown__meta .mob-lower .cats {
    margin-top: 20px;
  }
  .exhibitor-drilldown__meta .mob-lower .cats span {
    font-weight: bold;
  }
}
@media (min-width: 768px) {
  .exhibitor-drilldown__meta .mob-upper,
  .exhibitor-drilldown__meta .mob-lower {
    display: none;
  }
}

.fade.modal-backdrop.show {
  opacity: 0.5;
}

.exhibitor-drilldown__modal.modal.show {
  opacity: 1;
}
@media (max-width: 768px) {
  .exhibitor-drilldown__modal.modal.show {
    top: 10px;
  }
}
@media (min-width: 768px) {
  .exhibitor-drilldown__modal.modal.show .modal-content {
    top: 250px;
  }
}
.exhibitor-drilldown__modal.modal.show .modal-title {
  float: left;
}
.exhibitor-drilldown__modal.modal.show .modal-title.h4 {
  font-weight: bold;
}

.exhibitor-drilldown--CEX .enhanced-exhibitors__cta--premium-listing {
  background-color: #b88e14;
}
.exhibitor-drilldown--CEX .exhibitor-drilldown__cta {
  border-radius: 10px;
}
.exhibitor-drilldown--CEX .exhibitor-drilldown__horizontal h4,
.exhibitor-drilldown--CEX .exhibitor-drilldown__horizontal h5,
.exhibitor-drilldown--CEX .exhibitor-drilldown__horizontal .news__copy span,
.exhibitor-drilldown--CEX .exhibitor-drilldown__vertical h4,
.exhibitor-drilldown--CEX .exhibitor-drilldown__vertical h5,
.exhibitor-drilldown--CEX .exhibitor-drilldown__vertical .news__copy span {
  color: #b88e14;
}

.exhibitor-drilldown {
  border-top: 1px solid black;
  /**
  	RETROFITS FOR THIS VERSION TO WORK IN BOOTSTRAP 5
  */
}
.exhibitor-drilldown .exhibitor-drilldown__listing-cta .exhibitor-drilldown__cta {
  position: absolute;
  right: 0;
  z-index: 99;
  margin-top: -1px;
}
.exhibitor-drilldown .exhibitor-drilldown__listing-cta .exhibitor-drilldown__cta + h1 {
  margin-left: -15px;
}
@media (max-width: 768px) {
  .exhibitor-drilldown .exhibitor-drilldown__listing-cta .exhibitor-drilldown__cta {
    right: -15px;
  }
}
.exhibitor-drilldown .exhibitor-drilldown__content > div {
  margin-top: 20px;
}
.exhibitor-drilldown .exhibitor-drilldown__content .exhibitor-drilldown__bio {
  margin-top: 0px;
}
@media (max-width: 768px) {
  .exhibitor-drilldown .exhibitor-drilldown__quickMenu {
    display: none;
  }
}
.exhibitor-drilldown .exhibitor-drilldown__quickMenu > p {
  font-weight: bold;
}
.exhibitor-drilldown .exhibitor-drilldown__quickMenu > p span {
  cursor: pointer;
  font-weight: normal;
}
.exhibitor-drilldown .exhibitor-drilldown__quickMenu > p span:hover {
  color: black;
  text-decoration: underline;
  transition: opacity 0.3s;
  -webkit-transition: opacity 0.3s;
}
.exhibitor-drilldown .exhibitor-drilldown__quickMenu > p span:first-child {
  padding-left: 20px;
}
.exhibitor-drilldown .exhibitor-drilldown__quickMenu > p span:last-child::after {
  content: "";
}
.exhibitor-drilldown .exhibitor-drilldown__quickMenu > p span::after {
  content: "|";
  padding-left: 8px;
  padding-right: 8px;
}
@media (min-width: 990px) {
  .exhibitor-drilldown .exhibitor-drilldown__content {
    border-top: 1px solid black;
  }
}
.exhibitor-drilldown .exhibitor-drilldown__content .exhibitor-drilldown__horizontal > div,
.exhibitor-drilldown .exhibitor-drilldown__content .exhibitor-drilldown__vertical > div {
  padding-left: 0;
  padding-right: 0;
}
.exhibitor-drilldown .exhibitor-drilldown__content .exhibitor-drilldown__horizontal .exhibitor-drilldown__news > .mob,
.exhibitor-drilldown .exhibitor-drilldown__content .exhibitor-drilldown__vertical .exhibitor-drilldown__news > .mob {
  clear: both;
  min-height: 100px;
}
.exhibitor-drilldown .exhibitor-drilldown__bio {
  border-top: none;
}
@media (max-width: 576px) {
  .exhibitor-drilldown .hidden-xs {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .exhibitor-drilldown .visible-xs {
    display: block !important;
  }
}
@media (min-width: 1400px) {
  .exhibitor-drilldown .hidden-xxl {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .exhibitor-drilldown .visible-xxl {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .exhibitor-drilldown .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .exhibitor-drilldown .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .exhibitor-drilldown .hidden-md {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .exhibitor-drilldown .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .exhibitor-drilldown .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .exhibitor-drilldown .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .exhibitor-drilldown .hidden-xl {
    display: none !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .exhibitor-drilldown .visible-xl {
    display: block !important;
  }
}

.exhibitor-article__cta {
  background-color: black;
  border: none;
  color: #fff;
  padding: 5px 10px 5px 10px;
  transition: opacity 0.3s;
  -webkit-transition: 0.3s;
}
.exhibitor-article__cta:hover {
  opacity: 0.8;
}
.exhibitor-article__cta:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.exhibitor-article__cta--enhanced-listing, .exhibitor-article__cta--premium-listing {
  position: absolute;
  right: 0;
  margin-top: -1px;
  z-index: 99;
  cursor: default;
}
.exhibitor-article__cta--enhanced-listing:hover, .exhibitor-article__cta--premium-listing:hover {
  opacity: 1;
}
.exhibitor-article__cta--enhanced-listing:focus, .exhibitor-article__cta--premium-listing:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.exhibitor-article__cta--premium-listing {
  background-color: gold;
}
.exhibitor-article__cta--reset {
  right: 15px;
  position: absolute;
}
.exhibitor-article__cta--max {
  width: 100%;
  margin: 0px 0px 10px 0px;
  font-size: 2rem;
  font-weight: bold;
}
.exhibitor-article__cta--micro {
  font-size: 1rem;
  font-weight: normal;
}
.exhibitor-article__cta--reg {
  background-color: #a92e25;
}

.exhibitor-article__meta h4 {
  font-weight: bold;
}
@media (max-width: 768px) {
  .exhibitor-article__meta {
    min-height: 650px !important;
  }
}
.exhibitor-article__meta > div {
  padding-left: 20px;
}
@media (max-width: 768px) {
  .exhibitor-article__meta > div {
    padding-left: 0;
  }
}
.exhibitor-article__meta .main-cta {
  padding: 20px 0px 20px 0px;
}
@media (max-width: 768px) {
  .exhibitor-article__meta .main-cta {
    border-top: 1px solid black;
  }
}
.exhibitor-article__meta .booth {
  border-top: 1px solid black;
  padding: 5px 0px 5px 0px;
}
.exhibitor-article__meta .meta-data {
  border-top: 1px solid black;
  padding: 5px 0px 5px 0px;
}
@media (max-width: 768px) {
  .exhibitor-article__meta .meta-data {
    border-top: none;
  }
  .exhibitor-article__meta .meta-data div {
    margin-top: 30px;
  }
}
.exhibitor-article__meta .meta-data p {
  cursor: pointer;
  font-weight: bold;
}
.exhibitor-article__meta .meta-data .address {
  font-weight: normal;
  cursor: text;
}
.exhibitor-article__meta .meta-data .address span {
  font-weight: bold;
}
.exhibitor-article__meta .meta-data .address br + span {
  padding-left: 25px;
  display: inline-block;
}
.exhibitor-article__meta .message {
  border-top: 1px solid black;
  padding: 10px 0px 10px 0px;
}
.exhibitor-article__meta .message p {
  font-weight: bold;
}
.exhibitor-article__meta .glyphicon {
  padding-right: 5px;
}
@media (max-width: 768px) {
  .exhibitor-article__meta .message {
    display: none;
  }
  .exhibitor-article__meta .mob-lower {
    margin-top: 20px;
  }
}
@media (min-width: 768px) {
  .exhibitor-article__meta .mob-upper,
  .exhibitor-article__meta .mob-lower {
    display: none;
  }
}

.fade.modal-backdrop.show {
  opacity: 0.5;
}

.exhibitor-article__modal.modal.show {
  opacity: 1;
}
@media (max-width: 768px) {
  .exhibitor-article__modal.modal.show {
    top: 10px;
  }
}
@media (min-width: 768px) {
  .exhibitor-article__modal.modal.show .modal-content {
    top: 250px;
  }
}
.exhibitor-article__modal.modal.show .modal-title {
  float: left;
}
.exhibitor-article__modal.modal.show .modal-title.h4 {
  font-weight: bold;
}

.exhibitor-article--CEX .enhanced-exhibitors__cta--premium-listing {
  background-color: #b88e14;
}
.exhibitor-article--CEX .exhibitor-article__cta {
  border-radius: 10px;
}
.exhibitor-article--CEX .exhibitor-drilldown__horizontal h4 h5,
.exhibitor-article--CEX .exhibitor-drilldown__vertical h4 h5 {
  color: #b88e14;
}

.exhibitor-article {
  border-top: 1px solid black;
  /**
  	RETROFITS FOR THIS VERSION TO WORK IN BOOTSTRAP 5
  */
}
@media (max-width: 768px) {
  .exhibitor-article .listing-titles {
    margin-top: 50px;
  }
}
.exhibitor-article .listing-titles h1 {
  margin-top: 0px;
}
@media (max-width: 768px) {
  .exhibitor-article .listing-titles h1 {
    margin: 20px 0px 20px 0px;
  }
}
.exhibitor-article .listing-titles h2 {
  cursor: pointer;
}
.exhibitor-article .listing-titles h2:hover {
  opacity: 0.9;
}
.exhibitor-article .padded-cta {
  padding: 10px 0px 20px 0px;
}
.exhibitor-article .exhibitor-article__listing-cta .exhibitor-article__cta {
  position: absolute;
  right: 0;
  z-index: 99;
  margin-top: -1px;
}
.exhibitor-article .exhibitor-article__listing-cta .exhibitor-article__cta + h1 {
  margin-left: -15px;
}
@media (max-width: 768px) {
  .exhibitor-article .exhibitor-article__listing-cta .exhibitor-article__cta {
    right: -15px;
  }
}
@media (max-width: 768px) {
  .exhibitor-article .exhibitor-article__Product {
    border-top: 1px solid black;
    padding-top: 20px;
  }
}
.exhibitor-article .exhibitor-article__Product .image {
  float: left;
  max-width: 400px;
  padding: 5px 20px 5px 0px;
}
@media (max-width: 768px) {
  .exhibitor-article .exhibitor-article__Product .image {
    padding: 5px 0px 15px 0px;
  }
}
.exhibitor-article .exhibitor-article__listing-cta {
  display: none;
}
.exhibitor-article .padded-cta {
  padding: 10px 0px 20px 10px;
}
@media (max-width: 576px) {
  .exhibitor-article .hidden-xs {
    display: none !important;
  }
}
@media (max-width: 576px) {
  .exhibitor-article .visible-xs {
    display: block !important;
  }
}
@media (min-width: 1400px) {
  .exhibitor-article .hidden-xxl {
    display: none !important;
  }
}
@media (min-width: 1400px) {
  .exhibitor-article .visible-xxl {
    display: block !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .exhibitor-article .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 576px) and (max-width: 768px) {
  .exhibitor-article .visible-sm {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .exhibitor-article .hidden-md {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .exhibitor-article .visible-md {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .exhibitor-article .hidden-lg {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .exhibitor-article .visible-lg {
    display: block !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .exhibitor-article .hidden-xl {
    display: none !important;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  .exhibitor-article .visible-xl {
    display: block !important;
  }
}

.confman.conference-agenda .react__body .conference-agenda__sessions .body__session {
  margin: 5px 0;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--type-Break .session__flag {
  background-color: #2e2b28 !important;
  border-top: 15px solid #4e4845 !important;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--type-Break .session__content {
  background-color: #e4e2e2;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--type-Break .session__content .session__title h3 {
  color: #4e4845;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--type-Break .session__content .session__title .accordion-button {
  cursor: text;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--type-Break .session__content .session__title .accordion-button div {
  width: 100%;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--type-Break .session__content .session__title .accordion-button::after {
  background-image: unset !important;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__flag {
  background-color: red;
  border-top: 15px solid gray;
  padding: 0;
  padding-top: 1rem;
  text-align: center;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__flag h3 {
  color: #fff;
}
@media (max-width: 992px) {
  .confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__flag h3 {
    font-size: 17px;
  }
}
@media (max-width: 992px) {
  .confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content {
    padding: 0;
  }
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button {
  padding: 0.5rem 1.25rem;
  background-color: unset;
  border: none;
  border-top: none;
}
@media (min-width: 992px) {
  .confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button > div {
    min-width: 100%;
  }
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button .session--sponsored {
  display: flex;
  min-width: 100%;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button .session--sponsored .session__meta {
  width: 70%;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button .session--sponsored .session__sponsor {
  width: 30%;
  text-align: center;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button .session--sponsored .session__sponsor p {
  margin-bottom: 0;
  overflow-wrap: unset;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button > div {
  padding: 10px 5% 0 0;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button::after {
  /*Chevron*/
  margin-bottom: auto;
  color: inherit;
  font-weight: bold;
  background-image: url("https://www.onboardconnectivitysummit.com/assets/resources/shared/web/apps/confman/chevron.png");
  background-position: center;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__title .accordion-button:focus {
  box-shadow: unset;
  border-color: inherit;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body {
  padding: 0rem 1.25rem 0.5rem 1.25rem;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__video {
  position: relative;
  padding: 30%;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__video iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers {
  padding: 0.5rem 0;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker {
  padding: 0.5rem 0;
}
@media (max-width: 992px) {
  .confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker .speaker__presentation-name {
    padding: 0;
  }
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker .speaker__headshot {
  text-align: center;
}
@media (max-width: 992px) {
  .confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker .speaker__headshot {
    padding: 0;
  }
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker .speaker__info__job-title {
  font-weight: normal;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker .speaker__info__presentation {
  font-weight: normal;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session .session__content .session__body .accordion-body .body__speakers .speakers__speaker .speaker__company {
  text-align: center;
}
.confman.conference-agenda .react__body .conference-agenda__sessions .body__session.session--show .session__time {
  color: #333;
  font-weight: bold;
  transition: color 0.15s linear;
  transition: font-weight 0.15s linear;
}
.confman.speaker-list .react__body .speaker-list__slide-list {
  border-top: 3px solid black;
  padding: 10px 0;
}
.confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-headshot img {
  width: 100px;
}
@media (max-width: 992px) {
  .confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-headshot {
    padding: 0;
  }
}
.confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-details p {
  margin-bottom: 0;
}
.confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-details .speaker__name {
  font-weight: bold;
}
.confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-details .speaker__company {
  font-weight: bold;
}
.confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-company img {
  display: block;
  margin: auto;
}
@media (max-width: 992px) {
  .confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide .speaker-company {
    padding-left: 0;
  }
}
.confman.speaker-list .react__body .speaker-list__slide-list .confman__speaker-slide + .confman__speaker-slide {
  margin-top: 10px;
}

#od-modal h2 {
  color: black;
}
#od-modal button[type=submit] {
  padding: 3px 10px 5px 10px;
  margin-right: 0;
}
#od-modal button[type=submit]:hover {
  color: #b62f25;
}

.confman.conference-agenda--CEX .curved-cta--primary {
  background-color: #233166;
}
.confman.conference-agenda--CEX .curved-cta--primary .curved-cta__link {
  color: #fff;
}
.confman.conference-agenda--CEX .curved-cta--primary:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0px 1.5px #233166;
}
.confman.conference-agenda--CEX .curved-cta--primary:hover .curved-cta__link {
  color: #233166;
}
.confman.conference-agenda--CEX .curved-cta--primary.curved-cta--hard {
  background: #3d55b2;
}
.confman.conference-agenda--CEX .curved-cta--primary.curved-cta--hard:hover, .confman.conference-agenda--CEX .curved-cta--primary.curved-cta--hard.active {
  background: #233166;
  box-shadow: unset;
}
.confman.conference-agenda--CEX .curved-cta--primary.curved-cta--hard:hover .curved-cta__link, .confman.conference-agenda--CEX .curved-cta--primary.curved-cta--hard.active .curved-cta__link {
  color: #fff;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__days {
  background-color: #3d55b2;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__days .main-nav__nav-button {
  background-color: #3d55b2;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__days .main-nav__nav-button.active {
  background-color: #233166;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__days .main-nav__nav-button:hover {
  background-color: #233166;
  transition: background-color 0.25s ease-in;
  -webkit-transition: background-color 0.25s ease-in;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks {
  background-color: #e9e7f4;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter .checkbox-filter__name {
  margin-top: 0;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter .checkbox-filter__name h3 {
  color: #333333;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter.checkbox-filter--track-1 .bi-check-square-fill,
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter.checkbox-filter--track-1 .bi-square {
  color: #983620;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter.checkbox-filter--track-2 .bi-check-square-fill,
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter.checkbox-filter--track-2 .bi-square {
  color: #c7910c;
}
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter.checkbox-filter--track-3 .bi-check-square-fill,
.confman.conference-agenda--CEX .confman__main-nav .main-nav__tracks .checkbox-filter.checkbox-filter--track-3 .bi-square {
  color: #134f73;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-1 .session__flag {
  background-color: #983620;
  border-top: 15px solid #ab4b34;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-1 .session__title h3 {
  color: #983620;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-1 .session__body .body__speakers > h3 {
  color: #983620;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-1 .session__body .body__speakers .speaker__presentation-name {
  display: none;
  color: #983620;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-1 .session__body .body__speakers .speaker__info__name {
  color: #983620;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-1 .session__body .body__speakers .speaker__info__presentation {
  color: #983620;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-2 .session__flag {
  background-color: #c7910c;
  border-top: 15px solid #d8a61d;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-2 .session__title h3 {
  color: #c7910c;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-2 .session__body .body__speakers > h3 {
  color: #c7910c;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-2 .session__body .body__speakers .speaker__presentation-name {
  display: none;
  color: #c7910c;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-2 .session__body .body__speakers .speaker__info__name {
  color: #c7910c;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-2 .session__body .body__speakers .speaker__info__presentation {
  color: #c7910c;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-3 .session__flag {
  background-color: #134f73;
  border-top: 15px solid #16628f;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-3 .session__title h3 {
  color: #134f73;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-3 .session__body .body__speakers > h3 {
  color: #134f73;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-3 .session__body .body__speakers .speaker__presentation-name {
  display: none;
  color: #134f73;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-3 .session__body .body__speakers .speaker__info__name {
  color: #134f73;
}
.confman.conference-agenda--CEX .react__body .body__session.session--track-3 .session__body .body__speakers .speaker__info__presentation {
  color: #134f73;
}
.confman.conference-agenda--CEX .react__body .body__session .session__content .session__title .accordion-button::after {
  /*Chevron*/
}
.confman.conference-agenda--CEX .conference-agenda__secondary-nav .react__search span {
  background-color: #233166;
  cursor: pointer;
  transition: background-color 0.15s ease-in-out;
}
.confman.conference-agenda--CEX .conference-agenda__secondary-nav .react__search span:hover {
  background-color: #3d55b2;
}
.confman.conference-agenda--CEX .body__session .session__content .speaker__info__job-title {
  color: #333333;
}
.confman.speaker-list--TME .speaker__name {
  color: #233166;
}

@media (min-width: 992px) {
  .confman.conference-agenda .confman__main-nav {
    z-index: 999;
    position: relative;
  }
}
@media (min-width: 992px) {
  .confman.conference-agenda .confman__main-nav .main-nav__tracks {
    padding: 10px 0px;
  }
}
@media (max-width: 992px) {
  .confman.conference-agenda .confman__main-nav .main-nav__tracks .tracks__mob-control .control-col {
    justify-content: center;
  }
  .confman.conference-agenda .confman__main-nav .main-nav__tracks .tracks__mob-control .curved-cta--hard {
    margin: 10px 10px;
    padding: 5px 30px 3px 30px;
  }
  .confman.conference-agenda .confman__main-nav .main-nav__tracks .tracks__mob-tracks {
    background: #fff;
  }
}
.confman.conference-agenda .confman__main-nav .main-nav__tracks .track-col {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 992px) {
  .confman.conference-agenda .confman__main-nav .main-nav__tracks .track-col {
    justify-content: space-around;
  }
}
.confman.conference-agenda .confman__main-nav .main-nav__tracks .track-col .checkbox-filter {
  min-height: 50px;
  padding: 0 20px;
  display: flex;
  align-items: center;
}
.confman.conference-agenda .confman__main-nav .main-nav__tracks .track-col .checkbox-filter__checkbox {
  margin: 0 15px;
  -moz-transform: scale(2); /* FF */
  -webkit-transform: scale(2); /* Safari and Chrome */
  -o-transform: scale(2); /* Opera */
  transform: scale(2);
}
.confman.conference-agenda .confman__main-nav .main-nav__tracks .track-col .checkbox-filter__name h3 {
  margin-top: 10px;
  font-size: 16px;
}
@media (max-width: 992px) {
  .confman.conference-agenda .confman__main-nav .main-nav__tracks .track-col .checkbox-filter {
    padding: 10px;
  }
}
.confman.conference-agenda .confman__main-nav .main-nav__tracks .control-col {
  display: flex;
  align-items: center;
}
@media (max-width: 992px) {
  .confman.conference-agenda .confman__main-nav .main-nav__days .container-fluid {
    padding: 0;
  }
  .confman.conference-agenda .confman__main-nav .main-nav__days .container-fluid .row > div:first-child {
    border-left: none;
  }
  .confman.conference-agenda .confman__main-nav .main-nav__days .container-fluid .row > div:last-child {
    border-right: none;
  }
}
.confman.conference-agenda .confman__main-nav .main-nav__days .row > div {
  padding: 0;
  border-right: 1px solid #fff;
  border-left: 1px solid #fff;
}
.confman.conference-agenda .confman__main-nav .main-nav__days .main-nav__nav-button {
  cursor: pointer;
  padding: 10px 0;
}
.confman.conference-agenda .confman__main-nav .main-nav__days .main-nav__nav-button h3 {
  color: #fff;
  text-align: center;
  margin: 0;
}
.confman.speaker-list .confman__main-nav {
  margin-top: 20px;
}
.confman.speaker-list .confman__main-nav .container .row {
  align-items: center;
}
.confman.speaker-list .confman__main-nav .speaker-list__alphabet .row > .col-12 {
  display: flex;
  justify-content: flex-start;
}
.confman.speaker-list .confman__main-nav .confman__search input {
  padding: 10px;
}
.confman.speaker-list .confman__main-nav .react__cta {
  min-height: 48px;
}
@media (max-width: 990px) {
  .confman.speaker-list .confman__main-nav .react__cta {
    margin: 10px 0;
    width: 100%;
  }
}

.conference-agenda__secondary-nav.secondary-nav--affix {
  position: fixed;
  top: 80px;
}
.conference-agenda__secondary-nav .side-nav__search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.conference-agenda__secondary-nav .side-nav__search h3 {
  margin: 0;
}
.conference-agenda__secondary-nav .side-nav__search .curved-cta--hard.curved-cta--black {
  margin: 0;
  /*&:hover {
  	box-shadow: none;
  	background-color: lighten(black, $amount: 20);
  	transition: background-color 0.2s linear !important;
  	-webkit-transition: background-color 0.2s linear !important;
  	.cta__link {
  		color: #fff;
  	}
  }*/
}

.react__search {
  position: relative;
}
.react__search input {
  display: block;
  margin: auto;
  width: 100%;
}
.react__search span {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  text-align: center;
  color: #fff;
  font-weight: bold;
  width: 30px;
}

.confman .speaker-list__alphabet ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0;
  margin: 10px 0;
  flex-wrap: wrap;
}
@media (max-width: 990px) {
  .confman .speaker-list__alphabet ul {
    justify-content: center;
  }
}
.confman .speaker-list__alphabet ul a {
  font-size: 16px;
  white-space: nowrap;
}
.confman .speaker-list__alphabet ul a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.confman .speaker-list__alphabet ul a::after {
  content: "|";
  padding: 0 10px;
}
.confman .speaker-list__alphabet ul a::after:hover {
  text-decoration: none !important;
}
.confman .speaker-list__alphabet ul a:last-child::after {
  content: "";
  padding: 0;
}

.confman.conference-agenda .react__body {
  margin-top: 30px;
}
@media (max-width: 992px) {
  .confman.conference-agenda .react__body {
    margin-top: 10px;
  }
  .confman.conference-agenda .react__body > .container-fluid {
    padding: 0;
    margin: 0;
  }
  .confman.conference-agenda .react__body > .container-fluid > .row .col-12 {
    padding: 0;
    margin: 0;
  }
}
.confman.conference-agenda--loading {
  min-height: 500px;
}
.confman.conference-agenda .conference-agenda__sessions.sessions--error-message {
  text-align: center;
}
.confman .react__cta {
  height: fit-content;
}
.confman .react__cta.curved-cta--max {
  width: 100%;
}
.confman .react__cta.curved-cta--max .curved-cta__link {
  font-size: 20px;
}
.confman .react__cta.curved-cta--hard {
  border-radius: unset;
}
.confman .react__cta.curved-cta--hard.curved-cta--reg {
  padding: 5px 20px 3px 20px;
}
.modal.modal--speaker-list .modal-body img {
  margin: auto;
  display: block;
}

.exhibmanV2 .exhibitor-slide {
  position: relative;
  border-top: 1px solid black;
  height: 170px;
  padding: 10px 0;
  overflow: hidden;
}
@media (max-width: 992px) {
  .exhibmanV2 .exhibitor-slide {
    height: auto;
    min-height: 200px;
  }
}
.exhibmanV2 .exhibitor-slide .router-link--profile-button {
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
  margin-top: -1px;
  padding: 0 !important;
}
@media (max-width: 992px) {
  .exhibmanV2 .exhibitor-slide .router-link--profile-button {
    height: fit-content;
  }
}
.exhibmanV2 .exhibitor-slide .router-link--profile-button .exhibitor-slide__profile-button {
  padding: 0;
}
.exhibmanV2 .exhibitor-slide__profile-button--Standard .curved-cta__text {
  padding: 3px 10px 3px 10px;
}
.exhibmanV2 .exhibitor-slide__profile-button--Enhanced .curved-cta__text {
  padding: 3px 5px 3px 10px;
}
.exhibmanV2 .exhibitor-slide__profile-button:hover {
  background-color: #fff;
  box-shadow: inset 0 0 0 1.5px #000;
}
.exhibmanV2 .exhibitor-slide__profile-button:hover .curved-cta__exhib-assets {
  background-color: #fff !important;
  box-shadow: inset 0 0 0 1.5px #000;
  transition: background 0.2s linear !important;
}
.exhibmanV2 .exhibitor-slide__profile-button .curved-cta__link {
  display: block;
}
.exhibmanV2 .exhibitor-slide__profile-button .curved-cta__link .curved-cta__text,
.exhibmanV2 .exhibitor-slide__profile-button .curved-cta__link .curved-cta__exhib-assets {
  display: inline-block;
}
.exhibmanV2 .exhibitor-slide__profile-button .curved-cta__link .curved-cta__exhib-assets {
  display: inline-block;
  padding: 3px 5px 3px 5px;
  background-color: #5a5a5a;
}
.exhibmanV2 .exhibitor-slide__profile-button .curved-cta__link .curved-cta__exhib-assets span {
  padding: 3px;
}
.exhibmanV2 .exhibitor-slide__side-flag {
  position: absolute;
  width: 10px;
  background: gray;
  height: 90%;
  padding: 0;
}
.exhibmanV2 .exhibitor-slide__logo, .exhibmanV2 .exhibitor-slide__body, .exhibmanV2 .exhibitor-slide__cats {
  display: flex;
  align-items: center;
}
.exhibmanV2 .exhibitor-slide__logo {
  justify-content: center;
}
@media (max-width: 992px) {
  .exhibmanV2 .exhibitor-slide__logo {
    justify-content: flex-start;
  }
}
.exhibmanV2 .exhibitor-slide__body {
  justify-content: flex-start;
}
.exhibmanV2 .exhibitor-slide__body > div .exhibitor-name {
  margin-bottom: 5px;
}
.exhibmanV2 .exhibitor-slide__body > div .exhibitor-booth {
  margin-bottom: 0;
}
.exhibmanV2 .exhibitor-slide__cats {
  justify-content: flex-start;
}
@media (max-width: 992px) {
  .exhibmanV2 .exhibitor-slide__cats {
    justify-content: center;
  }
}
.exhibmanV2 .exhibitor-slide__cats p {
  font-size: 12px;
  color: #5c5c5c;
}
.exhibmanV2 .exhibitor-slide.exhibitor-slide--Enhanced .exhibitor-slide__logo img {
  cursor: pointer;
}
.exhibmanV2 .exhibitor-slide.exhibitor-slide--Enhanced .exhibitor-slide__logo img:hover {
  text-decoration: underline;
}
.exhibmanV2 .exhibitor-slide.exhibitor-slide--Enhanced .exhibitor-slide__body .exhibitor-name {
  cursor: pointer;
}
.exhibmanV2 .exhibitor-slide.exhibitor-slide--Enhanced .exhibitor-slide__body .exhibitor-name:hover {
  text-decoration: underline;
}

.exhibmanV2 .alphabet-filter {
  margin-bottom: 20px;
}
.exhibmanV2 .alphabet-filter .alphabet {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid black;
}
@media (max-width: 992px) {
  .exhibmanV2 .alphabet-filter .alphabet {
    justify-content: center;
  }
  .exhibmanV2 .alphabet-filter .alphabet .alphabet__letter {
    padding: 0 4px !important;
  }
  .exhibmanV2 .alphabet-filter .alphabet .alphabet__letter:first-child {
    padding-left: 0;
  }
  .exhibmanV2 .alphabet-filter .alphabet .alphabet__letter:last-child {
    padding-right: 0;
  }
}
.exhibmanV2 .alphabet-filter .alphabet .alphabet__letter {
  width: auto;
  max-width: unset;
  padding: 0;
  cursor: pointer;
  transition: font-weight 0.1s linear;
}
.exhibmanV2 .alphabet-filter .alphabet .alphabet__letter--active {
  font-weight: bold;
}

@media (max-width: 992px) {
  .control-col .react__sticky-nav.nav-affix--affix {
    top: 56px !important;
    width: 100% !important;
    background: #fff;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 10px;
    box-shadow: 0 4px 2px -2px gray;
  }
}
.control-col .react__sticky-nav.nav-affix--affix {
  position: fixed;
  top: 80px;
}
.control-col__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 2px solid black;
}
.control-col__header h3 {
  margin: 0;
  line-height: inherit;
}
.control-col__header .control-col__reset {
  margin: 0;
}
.control-col .control-col__download-list,
.control-col .control-col__reg-link,
.control-col .control-col__show-filter {
  width: 100%;
  margin: 10px 0px;
}
.control-col .control-col__show-filter span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.control-col .control-col__show-filter span span {
  color: #000000;
}
.control-col .control-col__show-filter span img {
  width: 20px;
}
.control-col .control-col__show-filter--a1x4V000001Yg1J {
  background-color: #fddecd;
  border-left: 10px solid #ed6839;
}
.control-col .control-col__show-filter--a1x4V000001Yg1J:hover {
  background-color: #feeee6 !important;
}
.control-col .control-col__show-filter--a1x3A000001FOPe {
  background-color: #cfddef;
  border-left: 10px solid #26358c;
}
.control-col .control-col__show-filter--a1x3A000001FOPe:hover {
  background-color: #e2ebf5 !important;
}
.control-col .control-col__show-filter--a1x4V000001Q9aU {
  background-color: #fddecd;
  border-left: 10px solid #ed6839;
}
.control-col .control-col__show-filter--a1x4V000001Q9aU:hover {
  background-color: #feeee6 !important;
}
.control-col .control-col__show-filter--a1x4V000001mqRx {
  background-color: #d3dce0;
  border-left: 10px solid #4e8891;
}
.control-col .control-col__show-filter--a1x4V000001mqRx:hover {
  background-color: #e2e8eb !important;
}
.control-col .control-col__show-filter--a1x4V000001mqS2 {
  background-color: #cfddef;
  border-left: 10px solid #2d88bd;
}
.control-col .control-col__show-filter--a1x4V000001mqS2:hover {
  background-color: #e2ebf5 !important;
}
.control-col .control-col__show-filter--a1xUK000000RaaT {
  background-color: #d8b29f;
  border-left: 10px solid #ad2d20;
}
.control-col .control-col__show-filter--a1xUK000000RaaT:hover {
  background-color: #dfc1b1 !important;
}
.control-col .control-col__show-filter--a1x4V0000026O9B {
  background-color: #e1d2b1;
  border-left: 10px solid #b88e14;
}
.control-col .control-col__show-filter--a1x4V0000026O9B:hover {
  background-color: #e8ddc3 !important;
}
.control-col .control-col__show-filter:hover {
  box-shadow: none;
}

.exhibmanV2.exhibman-profile .profile-quick-menu {
  padding: 15px 0;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__jump p {
  margin: 0;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__list {
  display: inline-flex;
  justify-content: flex-end;
  padding: 0 20px;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__list .profile-quick-menu__type {
  border-right: 1px solid black;
  padding: 0 15px;
  height: fit-content;
  cursor: pointer;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__list .profile-quick-menu__type p {
  margin: 0;
  line-height: normal;
  height: fit-content;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__list .profile-quick-menu__type:hover {
  text-decoration: underline;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__list .profile-quick-menu__type:first-child {
  padding-left: 0;
}
.exhibmanV2.exhibman-profile .profile-quick-menu__list .profile-quick-menu__type:last-child {
  border-right: none;
  padding-right: 0;
}
.exhibmanV2.exhibman-profile .profile-summary {
  padding: 20px 0;
  border-top: 1px solid black;
}
.exhibmanV2.exhibman-profile .profile-summary .profile-logo {
  max-width: 400px;
  padding: 10px;
}
@media (min-width: 992px) {
  .exhibmanV2.exhibman-profile .profile-summary .profile-logo {
    float: left;
  }
}
.exhibmanV2.exhibman-profile .profile-assets {
  padding: 20px 0;
  border-top: 1px solid black;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-products {
  clear: both;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-products .profile-product + .profile-product {
  margin-top: 20px;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-news {
  clear: both;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-news .profile-news + .profile-product {
  margin-top: 20px;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-videos .profile-video > div {
  position: relative;
  padding-bottom: 75%;
  overflow: hidden;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-videos .profile-video > div iframe {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-videos .profile-video p {
  text-align: center;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-downloadables .profile-downloadable > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-downloadables .profile-downloadable > div a {
  position: relative;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-downloadables .profile-downloadable > div a img {
  max-height: 200px;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-downloadables .profile-downloadable > div p {
  margin: 10px 0;
  text-align: center;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-downloadables .profile-downloadable > div .fi {
  position: absolute;
  bottom: -5px;
  right: -10px;
}
.exhibmanV2.exhibman-profile .profile-assets.profile-downloadables .profile-downloadable > div .fi .fi-content {
  padding-top: 10px;
  writing-mode: vertical-rl;
}
.exhibmanV2.exhibman-profile.exhibman-profile--Enhanced .profile-products .profile-product .profile-product__text h5 {
  cursor: pointer;
}
.exhibmanV2.exhibman-profile.exhibman-profile--Enhanced .profile-products .profile-product .profile-product__text h5:hover {
  text-decoration: underline;
}
.exhibmanV2.exhibman-profile.exhibman-profile--Enhanced .profile-products .profile-product .profile-product__text p span {
  cursor: pointer;
}
.exhibmanV2.exhibman-profile.exhibman-profile--Enhanced .profile-products .profile-product .profile-product__text p span:hover {
  text-decoration: underline;
}

/*!
 * CSS file icons v0.1.0 (https://colorswall.github.io/CSS-file-icons)
 * Copyright 2020 The CSS file icons Authors
 * Licensed under MIT
 */
.fi {
  width: 36px;
  height: 46px;
  padding: 10px 0 0;
  position: relative;
  margin: 0 auto;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  box-sizing: border-box;
  font-family: sans-serif;
  text-decoration: none;
  display: block;
}

.fi:before, .fi:after {
  position: absolute;
  content: "";
  pointer-events: none;
}

.fi:before {
  left: 0;
  top: 0;
  height: 100%;
  left: 0;
  background-color: #007bff;
  right: 10px;
}

.fi:after {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 10px;
  border-color: transparent transparent transparent #66b0ff;
  top: 0;
  right: 0;
}

.fi:hover {
  transform: translate(0, -5px);
}

.fi-content {
  background-color: #007bff;
  top: 10px;
  color: #fff;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 16.5px 0.3em 0;
  font-size: 13px;
  font-weight: 500;
  position: absolute;
}

.fi-mp3.fi:before {
  background-color: #156aea;
}

.fi-mp3.fi:after {
  border-left-color: #5291ef;
}

.fi-mp3.fi .fi-content {
  background-color: #156aea;
  color: #fff;
}

.fi-wav.fi:before {
  background-color: #36af14;
}

.fi-wav.fi:after {
  border-left-color: #4be520;
}

.fi-wav.fi .fi-content {
  background-color: #36af14;
  color: #fff;
}

.fi-aif.fi:before {
  background-color: #f55f73;
}

.fi-aif.fi:after {
  border-left-color: #f99daa;
}

.fi-aif.fi .fi-content {
  background-color: #f55f73;
  color: #fff;
}

.fi-cda.fi:before {
  background-color: #1081d1;
}

.fi-cda.fi:after {
  border-left-color: #34a2ef;
}

.fi-cda.fi .fi-content {
  background-color: #1081d1;
  color: #fff;
}

.fi-mid.fi:before {
  background-color: #21c6fc;
}

.fi-mid.fi:after {
  border-left-color: #62d7fd;
}

.fi-mid.fi .fi-content {
  background-color: #21c6fc;
  color: #fff;
}

.fi-midi.fi:before {
  background-color: #03afe7;
}

.fi-midi.fi:after {
  border-left-color: #30cafc;
}

.fi-midi.fi .fi-content {
  background-color: #03afe7;
  color: #fff;
}

.fi-mpa.fi:before {
  background-color: #8deef1;
}

.fi-mpa.fi:after {
  border-left-color: #c8f7f8;
}

.fi-mpa.fi .fi-content {
  background-color: #8deef1;
  color: #00013a;
}

.fi-mkv.fi:before {
  background-color: #ec93d8;
}

.fi-mkv.fi:after {
  border-left-color: #f6cbec;
}

.fi-mkv.fi .fi-content {
  background-color: #ec93d8;
  color: #00013a;
}

.fi-ogg.fi:before {
  background-color: #576c7b;
}

.fi-ogg.fi:after {
  border-left-color: #768d9e;
}

.fi-ogg.fi .fi-content {
  background-color: #576c7b;
  color: #fff;
}

.fi-wpa.fi:before {
  background-color: #214767;
}

.fi-wpa.fi:after {
  border-left-color: #316a99;
}

.fi-wpa.fi .fi-content {
  background-color: #214767;
  color: #fff;
}

.fi-wpl.fi:before {
  background-color: #2383f5;
}

.fi-wpl.fi:after {
  border-left-color: #62a7f8;
}

.fi-wpl.fi .fi-content {
  background-color: #2383f5;
  color: #fff;
}

.fi-7z.fi:before {
  background-color: #f63;
}

.fi-7z.fi:after {
  border-left-color: #ff9875;
}

.fi-7z.fi .fi-content {
  background-color: #f63;
  color: #fff;
}

.fi-zip.fi:before {
  background-color: #ffb229;
}

.fi-zip.fi:after {
  border-left-color: #ffca6b;
}

.fi-zip.fi .fi-content {
  background-color: #ffb229;
  color: #fff;
}

.fi-rar.fi:before {
  background-color: #ac3cc1;
}

.fi-rar.fi:after {
  border-left-color: #c26ed2;
}

.fi-rar.fi .fi-content {
  background-color: #ac3cc1;
  color: #1c148a;
}

.fi-tar-gz.fi:before {
  background-color: #c6ad86;
}

.fi-tar-gz.fi:after {
  border-left-color: #dbccb3;
}

.fi-tar-gz.fi .fi-content {
  background-color: #c6ad86;
  color: #665538;
}

.fi-pkg.fi:before {
  background-color: #955b2a;
}

.fi-pkg.fi:after {
  border-left-color: #c77b3a;
}

.fi-pkg.fi .fi-content {
  background-color: #955b2a;
  color: #ebc645;
}

.fi-z.fi:before {
  background-color: #b69663;
}

.fi-z.fi:after {
  border-left-color: #cbb490;
}

.fi-z.fi .fi-content {
  background-color: #b69663;
  color: #665538;
}

.fi-csv.fi:before {
  background-color: #579704;
}

.fi-csv.fi:after {
  border-left-color: #7cd806;
}

.fi-csv.fi .fi-content {
  background-color: #579704;
  color: #fff;
}

.fi-dat.fi:before {
  background-color: #0463ea;
}

.fi-dat.fi:after {
  border-left-color: #3587fc;
}

.fi-dat.fi .fi-content {
  background-color: #0463ea;
  color: #fff;
}

.fi-json.fi:before {
  background-color: #333;
}

.fi-json.fi:after {
  border-left-color: #545454;
}

.fi-json.fi .fi-content {
  background-color: #333;
  color: #aaa;
}

.fi-xml.fi:before {
  background-color: #0e886b;
}

.fi-xml.fi:after {
  border-left-color: #14c49a;
}

.fi-xml.fi .fi-content {
  background-color: #0e886b;
  color: #fff;
}

.fi-dat.fi:before {
  background-color: #8b9c35;
}

.fi-dat.fi:after {
  border-left-color: #b0c350;
}

.fi-dat.fi .fi-content {
  background-color: #8b9c35;
  color: #fff;
}

.fi-db.fi:before {
  background-color: #697628;
}

.fi-db.fi:after {
  border-left-color: #95a739;
}

.fi-db.fi .fi-content {
  background-color: #697628;
  color: #fff;
}

.fi-dbf.fi:before {
  background-color: #58e6a9;
}

.fi-dbf.fi:after {
  border-left-color: #92efc7;
}

.fi-dbf.fi .fi-content {
  background-color: #58e6a9;
  color: #fff;
}

.fi-sql.fi:before {
  background-color: #157efb;
}

.fi-sql.fi:after {
  border-left-color: #56a2fc;
}

.fi-sql.fi .fi-content {
  background-color: #157efb;
  color: #fff;
}

.fi-ns.fi:before {
  background-color: #249c3b;
}

.fi-ns.fi:after {
  border-left-color: #32d051;
}

.fi-ns.fi .fi-content {
  background-color: #249c3b;
  color: #422c21;
}

.fi-3ds.fi:before {
  background-color: #015051;
}

.fi-3ds.fi:after {
  border-left-color: #029192;
}

.fi-3ds.fi .fi-content {
  background-color: #015051;
  color: #fff;
}

.fi-max.fi:before {
  background-color: #02b4b6;
}

.fi-max.fi:after {
  border-left-color: #03f4f7;
}

.fi-max.fi .fi-content {
  background-color: #02b4b6;
  color: #fff;
}

.fi-ai.fi:before {
  background-color: #f67503;
}

.fi-ai.fi:after {
  border-left-color: #fd983f;
}

.fi-ai.fi .fi-content {
  background-color: #f67503;
  color: #fff;
}

.fi-psd.fi:before {
  background-color: #181040;
}

.fi-psd.fi:after {
  border-left-color: #2c1d75;
}

.fi-psd.fi .fi-content {
  background-color: #181040;
  color: #3db6f2;
}

.fi-ttf.fi:before {
  background-color: #174f57;
}

.fi-ttf.fi:after {
  border-left-color: #257f8b;
}

.fi-ttf.fi .fi-content {
  background-color: #174f57;
  color: #fff;
}

.fi-woff.fi:before {
  background-color: #1b8c73;
}

.fi-woff.fi:after {
  border-left-color: #26c4a1;
}

.fi-woff.fi .fi-content {
  background-color: #1b8c73;
  color: #fff;
}

.fi-woff2.fi:before {
  background-color: #177761;
}

.fi-woff2.fi:after {
  border-left-color: #22ae8f;
}

.fi-woff2.fi .fi-content {
  background-color: #177761;
  color: #fff;
}

.fi-png.fi:before {
  background-color: #dc7460;
}

.fi-png.fi:after {
  border-left-color: #e8a496;
}

.fi-png.fi .fi-content {
  background-color: #dc7460;
  color: #fff;
}

.fi-bmp.fi:before {
  background-color: #459fa0;
}

.fi-bmp.fi:after {
  border-left-color: #69bdbe;
}

.fi-bmp.fi .fi-content {
  background-color: #459fa0;
  color: #fff;
}

.fi-jpg.fi:before {
  background-color: #0074d0;
}

.fi-jpg.fi:after {
  border-left-color: #1397ff;
}

.fi-jpg.fi .fi-content {
  background-color: #0074d0;
  color: #fff;
}

.fi-jpeg.fi:before {
  background-color: #0066b7;
}

.fi-jpeg.fi:after {
  border-left-color: #008bf9;
}

.fi-jpeg.fi .fi-content {
  background-color: #0066b7;
  color: #fff;
}

.fi-gif.fi:before {
  background-color: #7979ec;
}

.fi-gif.fi:after {
  border-left-color: #b3b3f4;
}

.fi-gif.fi .fi-content {
  background-color: #7979ec;
  color: #fff;
}

.fi-tif.fi:before {
  background-color: #ce0258;
}

.fi-tif.fi:after {
  border-left-color: #fd1677;
}

.fi-tif.fi .fi-content {
  background-color: #ce0258;
  color: #fff;
}

.fi-tiff.fi:before {
  background-color: #b5024d;
}

.fi-tiff.fi:after {
  border-left-color: #f60269;
}

.fi-tiff.fi .fi-content {
  background-color: #b5024d;
  color: #fff;
}

.fi-svg.fi:before {
  background-color: #e6a420;
}

.fi-svg.fi:after {
  border-left-color: #edbc5c;
}

.fi-svg.fi .fi-content {
  background-color: #e6a420;
  color: #fff;
}

.fi-rss.fi:before {
  background-color: #fd8b33;
}

.fi-rss.fi:after {
  border-left-color: #feb075;
}

.fi-rss.fi .fi-content {
  background-color: #fd8b33;
  color: #fff;
}

.fi-torrent.fi:before {
  background-color: #55ac44;
}

.fi-torrent.fi:after {
  border-left-color: #7bc56d;
}

.fi-torrent.fi .fi-content {
  background-color: #55ac44;
  color: #fff;
}

.fi-ppt.fi:before {
  background-color: #ce4123;
}

.fi-ppt.fi:after {
  border-left-color: #e26b52;
}

.fi-ppt.fi .fi-content {
  background-color: #ce4123;
  color: #fff;
}

.fi-pps.fi:before {
  background-color: #bf4023;
}

.fi-pps.fi:after {
  border-left-color: #dd6347;
}

.fi-pps.fi .fi-content {
  background-color: #bf4023;
  color: #fff;
}

.fi-pptx.fi:before {
  background-color: #ac3a1f;
}

.fi-pptx.fi:after {
  border-left-color: #da5334;
}

.fi-pptx.fi .fi-content {
  background-color: #ac3a1f;
  color: #fff;
}

.fi-odp.fi:before {
  background-color: #38aef9;
}

.fi-odp.fi:after {
  border-left-color: #78c8fb;
}

.fi-odp.fi .fi-content {
  background-color: #38aef9;
  color: #fff;
}

.fi-asp.fi:before {
  background-color: #5c2d91;
}

.fi-asp.fi:after {
  border-left-color: #7c3dc3;
}

.fi-asp.fi .fi-content {
  background-color: #5c2d91;
  color: #fff;
}

.fi-c.fi:before {
  background-color: #3747a5;
}

.fi-c.fi:after {
  border-left-color: #5767c7;
}

.fi-c.fi .fi-content {
  background-color: #3747a5;
  color: #fff;
}

.fi-cs.fi:before {
  background-color: #013467;
}

.fi-cs.fi:after {
  border-left-color: #0255a9;
}

.fi-cs.fi .fi-content {
  background-color: #013467;
  color: #fff;
}

.fi-java.fi:before {
  background-color: #ea2c2e;
}

.fi-java.fi:after {
  border-left-color: #f0686a;
}

.fi-java.fi .fi-content {
  background-color: #ea2c2e;
  color: #fff;
}

.fi-jsp.fi:before {
  background-color: #e5000c;
}

.fi-jsp.fi:after {
  border-left-color: #ff2834;
}

.fi-jsp.fi .fi-content {
  background-color: #e5000c;
  color: #161419;
}

.fi-swift.fi:before {
  background-color: #f32a20;
}

.fi-swift.fi:after {
  border-left-color: #f6665f;
}

.fi-swift.fi .fi-content {
  background-color: #f32a20;
  color: #fff;
}

.fi-php.fi:before {
  background-color: #4f5b93;
}

.fi-php.fi:after {
  border-left-color: #717db3;
}

.fi-php.fi .fi-content {
  background-color: #4f5b93;
  color: #fff;
}

.fi-hh.fi:before {
  background-color: #505050;
}

.fi-hh.fi:after {
  border-left-color: #717171;
}

.fi-hh.fi .fi-content {
  background-color: #505050;
  color: #fff;
}

.fi-go.fi:before {
  background-color: #e0ebf5;
}

.fi-go.fi:after {
  border-left-color: #fff;
}

.fi-go.fi .fi-content {
  background-color: #e0ebf5;
  color: #000;
}

.fi-py.fi:before {
  background-color: #3472a3;
}

.fi-py.fi:after {
  border-left-color: #5294c8;
}

.fi-py.fi .fi-content {
  background-color: #3472a3;
  color: #ffd542;
}

.fi-js.fi:before {
  background-color: #f0db4f;
}

.fi-js.fi:after {
  border-left-color: #f5e78c;
}

.fi-js.fi .fi-content {
  background-color: #f0db4f;
  color: #323330;
}

.fi-html.fi:before {
  background-color: #e54c21;
}

.fi-html.fi:after {
  border-left-color: #ec7c5c;
}

.fi-html.fi .fi-content {
  background-color: #e54c21;
  color: #fff;
}

.fi-xhtml.fi:before {
  background-color: #55a9ef;
}

.fi-xhtml.fi:after {
  border-left-color: #92c8f5;
}

.fi-xhtml.fi .fi-content {
  background-color: #55a9ef;
  color: #fff;
}

.fi-css.fi:before {
  background-color: #264de4;
}

.fi-css.fi:after {
  border-left-color: #617deb;
}

.fi-css.fi .fi-content {
  background-color: #264de4;
  color: #fff;
}

.fi-vb.fi:before {
  background-color: #19aad9;
}

.fi-vb.fi:after {
  border-left-color: #4ac3ea;
}

.fi-vb.fi .fi-content {
  background-color: #19aad9;
  color: #fff;
}

.fi-rb.fi:before {
  background-color: #a20d01;
}

.fi-rb.fi:after {
  border-left-color: #e41201;
}

.fi-rb.fi .fi-content {
  background-color: #a20d01;
  color: #fff;
}

.fi-scss.fi:before {
  background-color: #bf4080;
}

.fi-scss.fi:after {
  border-left-color: #d072a1;
}

.fi-scss.fi .fi-content {
  background-color: #bf4080;
  color: #fff;
}

.fi-sass.fi:before {
  background-color: #b43c78;
}

.fi-sass.fi:after {
  border-left-color: #c69;
}

.fi-sass.fi .fi-content {
  background-color: #b43c78;
  color: #fff;
}

.fi-less.fi:before {
  background-color: #1d365d;
}

.fi-less.fi:after {
  border-left-color: #2d5390;
}

.fi-less.fi .fi-content {
  background-color: #1d365d;
  color: #fff;
}

.fi-jsx.fi:before {
  background-color: #61dafb;
}

.fi-jsx.fi:after {
  border-left-color: #a2e9fd;
}

.fi-jsx.fi .fi-content {
  background-color: #61dafb;
  color: #222;
}

.fi-sh.fi:before {
  background-color: #2a3238;
}

.fi-sh.fi:after {
  border-left-color: #46545e;
}

.fi-sh.fi .fi-content {
  background-color: #2a3238;
  color: #4da925;
}

.fi-pl.fi:before {
  background-color: #028fbd;
}

.fi-pl.fi:after {
  border-left-color: #05bffc;
}

.fi-pl.fi .fi-content {
  background-color: #028fbd;
  color: #fff;
}

.fi-xls.fi:before {
  background-color: #86d44c;
}

.fi-xls.fi:after {
  border-left-color: #aae181;
}

.fi-xls.fi .fi-content {
  background-color: #86d44c;
  color: #fff;
}

.fi-xlsx.fi:before {
  background-color: #6cbf2e;
}

.fi-xlsx.fi:after {
  border-left-color: #8ed758;
}

.fi-xlsx.fi .fi-content {
  background-color: #6cbf2e;
  color: #fff;
}

.fi-xlsm.fi:before {
  background-color: #60ab29;
}

.fi-xlsm.fi:after {
  border-left-color: #80d244;
}

.fi-xlsm.fi .fi-content {
  background-color: #60ab29;
  color: #fff;
}

.fi-ods.fi:before {
  background-color: #0c491e;
}

.fi-ods.fi:after {
  border-left-color: #158236;
}

.fi-ods.fi .fi-content {
  background-color: #0c491e;
  color: #fff;
}

.fi-dll.fi:before {
  background-color: #960a4a;
}

.fi-dll.fi:after {
  border-left-color: #d40e69;
}

.fi-dll.fi .fi-content {
  background-color: #960a4a;
  color: #fff;
}

.fi-bak.fi:before {
  background-color: #058bca;
}

.fi-bak.fi:after {
  border-left-color: #18b1f9;
}

.fi-bak.fi .fi-content {
  background-color: #058bca;
  color: #fff;
}

.fi-ini.fi:before {
  background-color: #0b2955;
}

.fi-ini.fi:after {
  border-left-color: #134590;
}

.fi-ini.fi .fi-content {
  background-color: #0b2955;
  color: #fff;
}

.fi-dmp.fi:before {
  background-color: #1960a2;
}

.fi-dmp.fi:after {
  border-left-color: #2282db;
}

.fi-dmp.fi .fi-content {
  background-color: #1960a2;
  color: #fff;
}

.fi-sys.fi:before {
  background-color: #abe6f1;
}

.fi-sys.fi:after {
  border-left-color: #e4f7fa;
}

.fi-sys.fi .fi-content {
  background-color: #abe6f1;
  color: #fff;
}

.fi-cfg.fi:before {
  background-color: #7fd9ea;
}

.fi-cfg.fi:after {
  border-left-color: #b8eaf3;
}

.fi-cfg.fi .fi-content {
  background-color: #7fd9ea;
  color: #fff;
}

.fi-tmp.fi:before {
  background-color: #69d2e6;
}

.fi-tmp.fi:after {
  border-left-color: #a2e3f0;
}

.fi-tmp.fi .fi-content {
  background-color: #69d2e6;
  color: #fff;
}

.fi-icns.fi:before {
  background-color: #b4e9f2;
}

.fi-icns.fi:after {
  border-left-color: #edfafc;
}

.fi-icns.fi .fi-content {
  background-color: #b4e9f2;
  color: #222;
}

.fi-doc.fi:before {
  background-color: #235d9c;
}

.fi-doc.fi:after {
  border-left-color: #317dd1;
}

.fi-doc.fi .fi-content {
  background-color: #235d9c;
  color: #fff;
}

.fi-docx.fi:before {
  background-color: #2980b9;
}

.fi-docx.fi:after {
  border-left-color: #4da1d8;
}

.fi-docx.fi .fi-content {
  background-color: #2980b9;
  color: #fff;
}

.fi-log.fi:before {
  background-color: #accff3;
}

.fi-log.fi:after {
  border-left-color: #e6f0fb;
}

.fi-log.fi .fi-content {
  background-color: #accff3;
  color: #fff;
}

.fi-txt.fi:before {
  background-color: #8bc6d6;
}

.fi-txt.fi:after {
  border-left-color: #bcdee7;
}

.fi-txt.fi .fi-content {
  background-color: #8bc6d6;
  color: #fff;
}

.fi-pdf.fi:before {
  background-color: #f88e21;
}

.fi-pdf.fi:after {
  border-left-color: #faaf61;
}

.fi-pdf.fi .fi-content {
  background-color: #f88e21;
  color: #fff;
}

.fi-avi.fi:before {
  background-color: #40c1e6;
}

.fi-avi.fi:after {
  border-left-color: #7bd4ee;
}

.fi-avi.fi .fi-content {
  background-color: #40c1e6;
  color: #fff;
}

.fi-mov.fi:before {
  background-color: #ff5838;
}

.fi-mov.fi:after {
  border-left-color: #ff907a;
}

.fi-mov.fi .fi-content {
  background-color: #ff5838;
  color: #fff;
}

.fi-mp4.fi:before {
  background-color: #4163b4;
}

.fi-mp4.fi:after {
  border-left-color: #6d89ca;
}

.fi-mp4.fi .fi-content {
  background-color: #4163b4;
  color: #fff;
}

.fi-wmv.fi:before {
  background-color: #f76205;
}

.fi-wmv.fi:after {
  border-left-color: #fb8a43;
}

.fi-wmv.fi .fi-content {
  background-color: #f76205;
  color: #fff;
}

.fi-mpg.fi:before {
  background-color: #1298d6;
}

.fi-mpg.fi:after {
  border-left-color: #3cb6ef;
}

.fi-mpg.fi .fi-content {
  background-color: #1298d6;
  color: #fff;
}

.fi-mpeg.fi:before {
  background-color: #0e77a7;
}

.fi-mpeg.fi:after {
  border-left-color: #13a2e4;
}

.fi-mpeg.fi .fi-content {
  background-color: #0e77a7;
  color: #fff;
}

.fi-mkv.fi:before {
  background-color: #0c668f;
}

.fi-mkv.fi:after {
  border-left-color: #1191cd;
}

.fi-mkv.fi .fi-content {
  background-color: #0c668f;
  color: #fff;
}

.fi-wps.fi:before {
  background-color: #297eff;
}

.fi-wps.fi:after {
  border-left-color: #6ba6ff;
}

.fi-wps.fi .fi-content {
  background-color: #297eff;
  color: #fff;
}

.fi-exe.fi:before {
  background-color: #0e63ab;
}

.fi-exe.fi:after {
  border-left-color: #1386e8;
}

.fi-exe.fi .fi-content {
  background-color: #0e63ab;
  color: #fff;
}

.fi-size-xs.fi {
  width: 28.8px;
  height: 36.8px;
  padding-top: 8px;
}

.fi-size-xs.fi:before {
  right: 8px;
}

.fi-size-xs.fi:after {
  border-top-width: 8px;
  border-left-width: 8px;
}

.fi-size-xs.fi .fi-content {
  top: 8px;
  padding-top: 13.2px;
  font-size: 10.4px;
}

.fi-size-sm.fi {
  width: 36px;
  height: 46px;
  padding-top: 10px;
}

.fi-size-sm.fi:before {
  right: 10px;
}

.fi-size-sm.fi:after {
  border-top-width: 10px;
  border-left-width: 10px;
}

.fi-size-sm.fi .fi-content {
  top: 10px;
  padding-top: 16.5px;
  font-size: 13px;
}

.fi-size-md.fi {
  width: 43.2px;
  height: 55.2px;
  padding-top: 12px;
}

.fi-size-md.fi:before {
  right: 12px;
}

.fi-size-md.fi:after {
  border-top-width: 12px;
  border-left-width: 12px;
}

.fi-size-md.fi .fi-content {
  top: 12px;
  padding-top: 19.8px;
  font-size: 15.6px;
}

.fi-size-lg.fi {
  width: 54px;
  height: 69px;
  padding-top: 15px;
}

.fi-size-lg.fi:before {
  right: 15px;
}

.fi-size-lg.fi:after {
  border-top-width: 15px;
  border-left-width: 15px;
}

.fi-size-lg.fi .fi-content {
  top: 15px;
  padding-top: 24.75px;
  font-size: 19.5px;
}

.fi-size-xl.fi {
  width: 72px;
  height: 92px;
  padding-top: 20px;
}

.fi-size-xl.fi:before {
  right: 20px;
}

.fi-size-xl.fi:after {
  border-top-width: 20px;
  border-left-width: 20px;
}

.fi-size-xl.fi .fi-content {
  top: 20px;
  padding-top: 33px;
  font-size: 26px;
}

.fi-content-xs .fi-content {
  font-size: 11px;
  padding-top: 55%;
}

.fi-round-sm.fi {
  border-radius: 2px;
  overflow: hidden;
}

.fi-round-md.fi {
  border-radius: 4px;
  overflow: hidden;
}

@media (max-width: 992px) {
  .meta-info-col .react__sticky-nav.nav-affix--affix {
    top: 56px !important;
    width: 100% !important;
    background: #fff;
    left: 0;
    right: 0;
    z-index: 1;
    padding: 10px;
    box-shadow: 0 4px 2px -2px gray;
  }
}
.meta-info-col .react__sticky-nav.nav-affix--affix {
  position: fixed;
  top: 80px;
}
.meta-info-col .curved-cta--max {
  width: 100%;
}
.meta-info-col .curved-cta--max a {
  font-size: 1rem;
}
.meta-info-col__button {
  width: 100%;
}
@media (max-width: 992px) {
  .meta-info-col__button {
    margin-left: auto;
    margin-right: auto;
  }
}
.meta-info-col__booth-num {
  text-align: center;
  margin-bottom: 10px;
  padding: 10px 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}
.meta-info-col__booth-num h4 {
  margin: 0;
}
.meta-info-col__address p {
  margin-left: 20px;
}
.meta-info-col__address p:first-child {
  margin-left: 0;
}

.exhibmanV2.exhibman-product .product-header h2 {
  cursor: pointer;
}
.exhibmanV2.exhibman-product .product-header h2:hover {
  text-decoration: underline;
}
.exhibmanV2.exhibman-product .product-body__image {
  max-width: 400px;
  padding: 10px;
}
@media (min-width: 992px) {
  .exhibmanV2.exhibman-product .product-body__image {
    float: left;
  }
}
.exhibmanV2.exhibman-product .profile-back-to-list {
  display: none;
}

.exhibmanV2--CEX .product-header h2 a {
  color: #b88e14;
}
.exhibmanV2--CEX .control-col .nav-affix--fixed-top .control-col__sponsor {
  display: none;
}
.exhibmanV2--CEX .control-col .control-col__show-filter {
  text-transform: uppercase;
}
.exhibmanV2--CEX .exhibitor-slide.exhibitor-slide--TME .exhibitor-slide__side-flag {
  background: #ad2d20;
}
.exhibmanV2--CEX .exhibitor-slide.exhibitor-slide--CEX .exhibitor-slide__side-flag {
  background: #b88e14;
}

@media (min-width: 992px) {
  .exhibmanV2 {
    margin-top: 20px;
  }
}
.exhibmanV2 .viewport-col .viewport__header h3 {
  padding: 0;
  margin: 0;
}
.exhibmanV2 .router-link:hover {
  text-decoration: none;
}

#root {
  min-height: 75vh;
}
@media (max-width: 992px) {
  #root {
    min-height: 30vh !important;
  }
}