@use "sass:color";
$main-color: #b18b2f;
$reg-color: #b62f25;

body {
    margin-left: 0;
    margin-right: 0;
}
header {
    @media screen and (min-width: 990px) {
        &.header--desktop {
            .header__covid-info {
                top: 4px;
                position: absolute;
            }
        }
        &.fixed-top {
            height: 50px;
            background: #fff;
            box-shadow: 0 3px 5px;
            .navbar {
                padding-bottom: 0;
                padding-top: 0;
            }
            .navbar {
                padding-bottom: 0;
            }
            .header__sub-menu,
            .header__info,
            .header__info--bonus,
            .header__main-logo,
            .header__covid-info {
                display: none !important;
            }
            .header__main-menu {
                min-height: unset;
                .header__locked-logo {
                    display: flex;
                    height: 50px;
                    justify-content: center;
                    align-items: center;
                }
                nav {
                    height: 50px;
                }
            }
        }
        &.header--default {
            border-top: 5px solid $main-color;
            .header__locked-logo {
                display: none;
            }
        }
    }
    @media screen and (max-width: 990px) {
        & > .container-fluid {
            padding-left: 0;
            padding-right: 0;
            .navbar-brand {
                a {
                    font-size: 20px;
                    color: #fff;
                    padding: 5px;
                }
            }
        }
        nav {
            //padding-bottom: 0;
            .collapse,
            .collapsing {
                .navbar-nav {
                    justify-content: center;
                    align-items: center;
                    .nav-item {
                        text-align: center;
                        width: 100%;
                        border-top: 1px solid gray;
                        .nav-link {
                            color: #fff;
                            font-weight: normal;
                        }
                        &--reg--link {
                            background-color: $reg-color;
                        }
                        &.nav-item--secondary-menu {
                            .dropdown-menu {
                                background-color: #595757;
                                border-radius: 0px;
                                text-align: center;
                                margin-top: -1px;
                                margin-bottom: 0;
                                li {
                                    & + li {
                                        border-top: 1px solid gray;
                                    }
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .header__details {
            margin-top: 56px;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .header__main-logo {
                padding-top: 10px;
                padding-bottom: 10px;
            }
            &__co-locate{
                max-width:140px;
                padding-bottom:10px;
            }
            .header__info {
                .header__info--location,
                .header__info--date {
                    display: block;
                    text-align: center;
                    margin: 0;
                }
                .header__info--location {
                    //margin-bottom: 5px;
                }
            }
        }
    }
    .header__sub-menu {
        height: 70px;
        .header__sub-menu__list {
            list-style-type: none;
            float: right;
            display: flex;
            justify-content: space-between;
            margin-bottom: 0;
            li {
                padding: 0 10px;
            }
            a {
                text-decoration: none;
                font-weight: normal;
                font-size: 12px;
                color: #6a6768;
                -webkit-transition: color 0.2s linear;
                &:focus {
                    color: #6a6768;
                }
                &:hover {
                    color: darken($color: #333333, $amount: 40%);
                }
            }
            //For search
            &.active {
                .header__search {
                    div {
                        input {
                            outline: none !important;
                            border: 1px solid #6a6768;
                            border-left: none;
                            border-right: none;
                            width: 370px;
                            opacity: 1 !important;
                            font-size: 12px;
                            display: block;
                        }
                        img {
                            &:last-child {
                                display: block !important;
                            }
                        }
                    }
                }
            }
            .header__search {
                form > div {
                    display: inline-flex;
                    height: 100%;
                    input {
                        //transition: width 0.25s linear, opacity 0.25s linear;
                        //-webkit-transition: width 0.25s linear, opacity 0.25s linear;
                        opacity: 0;
                        width: 0px;
                        height: 30px;
                        display: none;
                    }
                    img {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        &:last-child {
                            display: none;
                            border: 1px solid #6a6768;
                        }
                    }
                }
            }
        }
        .header__sub-menu__co-locate {
            clear: both;
            float: right;
            margin-top: 5px;
            @media (max-width: 1200px) {
                //display: none;
                 margin-top: 0px;
            }
            @media (min-width: 1400px) {
                margin-right: 100px;
            }
            & > div {
                max-width: 150px;
                display: inline-block;
                //padding: 10px;
            }
        }
    }
    .header__main-menu {
        min-height: 90px;
        nav {
            & > div {
                padding: 0;
                .navbar-nav {
                    .nav-item {
                        .active {
                            color: $main-color !important;
                            font-weight: bold !important;
                            &::after {
                                color: #333333 !important;
                            }
                        }
                        .nav-link {
                            font-weight: 700;
                            color: #333333;
                            font-size: 14px;
                               padding: 0px;
                            -webkit-transition: color 0.2s linear;
                            text-decoration: none;
                            @media (min-width: 990px) and (max-width: 1200px) {
                                font-size: 12px;
                            }
                         
                            &:hover {
                                color: lighten($color: #333333, $amount: 30%);
                            }
                            &::after {
                                content: "|";
                                text-decoration: none;
                                padding: 0px 8px 0px 8px;
                                @media (min-width: 990px) and (max-width: 1200px) {
                                    padding: 0px 4px 0px 4px;
                                }
                            }
                        }
                        &:nth-last-child(2) {
                            .nav-link {
                                &::after {
                                    //for nav reg link
                                   content: "";
                                }
                            }
                        }
                        &.nav-item--reg-link {
                            margin-top: -5px;
                            background-color: $reg-color;
                            border-radius: 5px;
                            padding: 0 7px;
                            transition: background 0.2s linear;
                            -webkit-transition: background 0.2s linear;
                            transition: color 0.2s linear;
                            -webkit-transition: color 0.2s linear;
                            .nav-link {
                                text-decoration: none;
                                padding: 3px 0px 0px 0px;
                                font-weight: bold;
                                color: #fff;
                            }
                            //anim
                            &:hover {
                                background-color: #fff;
                                box-shadow: inset 0 0 0 1.5px $reg-color;
                                .nav-link {
                                    color: $reg-color;
                                }
                            }
                        }

                        &:first-child {
                            a {
                                padding-left: 0;
                            }
                        }
                        &:last-child {
                            .nav-link {
                                &:after {
                                    content: "";
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
        .header__main-logo {
            display: flex;
            justify-content: center;
            height: 100%;
            img {
                width: 100%;
            }
        }
    }
    .header__info {
        margin: 0;
        &--date,
        &--location {
            display: inline-block;
            font-size: 17px;
        }
        &--date {
            color: #333333;
            font-weight: bold;
            margin-right: 20px;
        }
        &--location {
            color: #333333;
            font-weight: bold;
            text-align:center;
        }
        h2 {
            margin-bottom: 0;
        }
    }
    .header__info--bonus {
        margin: 0;
        p {
            font-size: 16px;
            margin: 0;
            span {
                color: $main-color;
                font-weight: bold;
            }
        }
    }
}

//!!!!!NEW HEADER!!!!
header#new {
    //MOBILE
   
    @media screen and (min-width: 990px) {
        &.header--desktop {
            .header__covid-info {
                top: 4px;
                position: absolute;
            }
        }
        &.fixed-top {
            height: 31px;
            background: #fff;
            box-shadow: 0 3px 5px;
            .navbar {
                padding-bottom: 0;
                padding-top: 0;
            }
            //.navbar {
            //    padding-bottom: 0;
           // }
            .header__sub-menu,
            .header__info,
            .header__info--bonus,
            .header__main-logo,
            .header__covid-info {
                display: none !important;
            }
            .header__main-menu {
                min-height: unset;
                .header__locked-logo {
                    display: flex;
                    height: 50px;
                    justify-content: center;
                    align-items: center;
                }
                nav {
                    height: 31px;
                }
            }
        }
        &.header--default {
            border-top: 5px solid $main-color;
              .header__main-menu {
                margin-top: 0px;
            }
            .header__locked-logo {
                display: none;
            }
        }
    }
    @media screen and (max-width: 990px) {
        & > .container-fluid {
            padding-left: 0;
            padding-right: 0;
            .navbar-brand {
                a {
                    font-size: 20px;
                    color: #fff;
                    padding: 5px;
                }
            }
        }
        .navbar--tme-onepage {
            height: 56px;
        }
        nav {
            //padding-bottom: 0;
            .collapse,
            .collapsing {
                //MAIN TABS
                .navbar-nav {
                    
                    justify-content: flex-start;
                    align-items: center;
                    
                 
                    li {
                        text-align: center;
                        width: 100%;
                        border-top: 1px solid gray;
                        text-transform: uppercase;
                        .nav-link {
                            color: #fff;
                            font-weight: normal;
                            //.bi.bi-house-fill{
                              //   font-size:19px;
                           // }
                        }
                       // &--reg--link {
                           // background-color: $reg-color;
                      //  }
                        &.nav-item--secondary-menu {
                            .dropdown-menu {
                                background-color: #595757;
                                border-radius: 0px;
                                text-align: center;
                                margin-top: -1px;
                                margin-bottom: 0;
                                li {
                                    & + li {
                                        border-top: 1px solid gray;
                                    }
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                    
                      //SECONDARY TABS    
                     ul.submenu{
                         //SET SECONDARY TABS CLOSED BY DEFAULT 
                         display:none;
                        padding:0; 
                        li{
                            text-transform:capitalize;
                            background-color:#595757;
                        }
                     }
                     li.active{
                         //OPEN SUBMENU FOR SELECTED TAB
                         ul.submenu{
                             display:block;
                             li.active a{border-left:2px solid $main-color;border-right:2px solid $main-color;}
                         }
                     }
                }
            }
        }
        .header__details {
            margin-top: 56px;
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 990px) {
            margin-top: 0px;
            padding-top: 0px;
            }
            .header__main-logo {
                padding-top: 110px;
                padding-bottom: 10px;
            }
            .header__info {
                .header__info--location,
                .header__info--date {
                    display: block;
                    text-align: center;
                    margin: 0;
                }
                .header__info--location {
                    margin-bottom: 5px;
                }
            }
            .header__info--bonus {
                text-align: center;
            }
        }
    }
    
    
        .header__main-logo {
          //  display: flex;
           // justify-content: center;
            //height: 100%;
            
            position:relative;
            top:5px;
            @media screen and (max-width: 990px) {
                padding:10px;
            }
            img {
                width: 100%;
                
            }
        }
    .header__main-logo.co-located-logo{
        position:absolute;
        top:unset;
        top: auto;
         img {
                width: 80%;
            }
    }
    
    
    
    .header__sub-menu {
        height: 50px;
        .header__sub-menu__list {
            list-style-type: none;
            float: right;
            display: flex;
            padding-top:3px;
            justify-content: space-between;
            margin-bottom: 0;
            	//Space for google translate  
			padding-right:145px;
			@media (min-width: 990px) and (max-width: 1200px) {
			    padding-top:2px;
			}
			@media only screen and (min-width: 1441px) {
			    padding-right:246px;
			}
			
            li {
                //padding: 0 10px;
            }
            a {
                text-decoration: none;
                font-weight: normal;
                font-size: 15px;
                color: #6a6768;
                -webkit-transition: color 0.2s linear;
                &:focus {
                    color: #6a6768;
                }
                &:hover {
                    color: darken($color: #333333, $amount: 40%);
                }
            }
            //For search
            &.active {
                .header__search {
                    div {
                        input {
                            outline: none !important;
                            border: 1px solid #6a6768;
                            border-left: none;
                            border-right: none;
                            width: 370px;
                            opacity: 1 !important;
                            font-size: 12px;
                            display: block;
                        }
                        img {
                            &:last-child {
                                display: block !important;
                            }
                        }
                    }
                }
            }
            .header__search {
                form > div {
                    display: inline-flex;
                    height: 100%;
                    input {
                        //transition: width 0.25s linear, opacity 0.25s linear;
                        //-webkit-transition: width 0.25s linear, opacity 0.25s linear;
                        opacity: 0;
                        width: 0px;
                        height: 30px;
                        display: none;
                    }
                    img {
                        cursor: pointer;
                        width: 30px;
                        height: 30px;
                        &:last-child {
                            display: none;
                            border: 1px solid #6a6768;
                        }
                    }
                }
            }
        }
        .header__sub-menu__co-locate {
            clear: both;
            float: right;
            margin-top: 25px;
            @media (max-width: 1200px) {
                display: none;
            }
            @media (min-width: 1200px) {
                margin-right: 0px;
            }
            @media (min-width: 1400px) {
                margin-right: 100px;
            }
            & > div {
                max-width: 150px;
                display: inline-block;
                //padding: 10px;
            }
        }
    }
    .header__main-menu { 
        
        //changing this 
        min-height: 0px;
        //start replacing
        
        .collapse.navbar-collapse{
            justify-content:center;
        }
       
         // Menu
  .navbar{
   padding:0;   
   justify-content:center;
  }
  .navbar-nav {
    justify-content:flex-start;
    align-items: center;
    padding:0;

    li a.nav-link {
      text-transform: uppercase;
      -webkit-transition: color 0.2s linear;
      text-decoration: none;
      font-size: 13px;
     
       padding:0px 10px 0 10px!important;
      font-weight: bold;
      display: flex;
    }

    // Submenu closed by default
    li ul {
      display: none;
    }

    // Menu main tabs
    li {
     // padding: 0 10px!important;

      a {
        color: black;

        .bi.bi-house-fill {
          font-size: 19px;
        }
      }

      &:hover {
        background: black;
        border-radius: 0;
      //  padding: 0 10px!important;
        box-shadow: inset 0 0 0 1.5px transparent;

        a {
          color: white;
        }
      }
    }

    // Menu active main tab
    li.active {
      color: #ad2d20;
      background: black;
      font-weight: 1000 !important;
      color: #ffffff !important;
     // padding:5px 10px 0 10px!important;

      &::after {
        color: #333333 !important;
      }

      a {
        color: #fff;
      }

      ul {
        display: block;
        position: absolute;
        z-index: 1;
        background: white;
        width: 100%;
        left: 0;
        right: 0;
        display: flex;
        list-style: none;
        justify-content: center;
        background: black;
        padding: 0;

        // Submenu li
        li {
          padding: 0px !important;
          font-size: 13px;

          &.active {
            padding: 0 !important;
          }

          // Active sub tab
          &.active > a {
            color: $main-color;
          }

          // Sub tab
          a {
            vertical-align: text-top;
            text-transform: uppercase;
            color: white;
            text-decoration: none;

            &:hover {
              color: $main-color !important;
            }
          }
        }
      }
    }

    li.last {
      margin-top: 0;
      padding: 0;

      a {
        color: $main-color;
        transition: background 0.2s linear;
        -webkit-transition: background 0.2s linear;
        transition: color 0.2s linear;
        -webkit-transition: color 0.2s linear;
      }
    }
  }
        //end start replacing
        // replaceing this
        /*nav {
            & > div {
                padding: 0;
                .navbar-nav {
                    //justify-content:center;
                    .nav-item {
                        //padding-left:0!important;padding-right:0!important; 
                        .active {
                           
                            font-weight: 1000 !important;
                             color: #ffffff!important ;
                             font-weight: 1000 !important;
                             border:1px solid red;
                             background:black;
                             padding: 5px 10px 5px 10px!important;
                            &::after {
                               // color: #333333 !important;
                            }
                        }
                        .nav-link {
                            //here
                            color: red;
                           -webkit-transition: color 0.2s linear;
                            text-decoration: none;
                             font-size: 13px;
                             padding-top: 10px;
                             padding:5px 10px 0 10px!important;
                             font-weight:bold;
                             display:flex;
                              //.bi.bi-house-fill{
                                 //font-size:19px;
                            //}
                            @media (min-width: 990px) and (max-width: 1200px) {
                                font-size: 12px;
                            }
                            
                            &:hover {
                               // color:#fff;
                              //  background:black;
                                //color: lighten($color: #333333, $amount: 30%);
                                
                            }
                            &::after {
                             //   content: "";
                               // color: $main-color !important;
                                //font-weight: normal;
                                //text-decoration: none;
                                //padding: 0px ;
                                @media (min-width: 990px) and (max-width: 1200px) {
                                    padding: 0px 4px 0px 4px;
                                }
                            }
                        }
                        //SET SUBMENU BAR NO DISPLAY BY DEFAULT
                         //ul.submenu {display: none;}
                        //ACTIVATE SUBMENU IF THE TAB HAS CHILDREN AND HAS CLASS ACTIVE ()
                         .nav-link.active + .submenu{
                             /*   display: block;
                                position: absolute;
                                z-index: 1;
                                background: white;
                                width: 100%;
                                left: 0;
                                right: 0;
                                display:flex;
                                list-style:none;
                                justify-content:center;
                                background: black;
                                padding:0;
                         }
                        .nav-link{
                            //submenu part
                           
                            &.active + .submenu {
                                .nav-item-sub{
                                    // padding :0 10px;
                                        //  font-size:13px;
                                          .nav-link-sub{
                                        //  vertical-align:text-top;
                                          //    text-transform:uppercase;
                                           //   color:white!important;
                                            //  text-decoration:none;
                                         // &:hover{color:$main-color!important;}
                                          }
                                
                                }
                                
                            }
                        }
                        
                        &.nav-item--reg-link {
                            //margin-top: -5px;
                            //background-color: $reg-color;
                            //border-radius: 5px;
                           // padding: 0 7px;
                            // margin-top:0;
                           // background-color: transparent;
                            //border-radius: 5px;
                          // padding: 0 ;
                           //color:$main-color;
                          //  transition: background 0.2s linear;
                            //-webkit-transition: background 0.2s linear;
                            //transition: color 0.2s linear;
                            //-webkit-transition: color 0.2s linear;
                            .nav-link {
                               // text-decoration: none;
                               // padding: 4px 0px 0px 0px;
                               // font-weight: 1000;
                              //  color: $reg-color;
                            }
                            //anim
                            &:hover {
                               // background-color: #fff;
                               //background-color: transparent;
                               background:black;
                               border-radius:0;
                                box-shadow: inset 0 0 0 1.5px transparent;
                                .nav-link {
                                    color: $reg-color;
                                }
                            }
                        }

                        &:first-child {
                            a {
                                padding-left: 0;
                            }
                        }
                        &:last-child {
                            .nav-link {
                                &:after {
                                    content: "";
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }*/
//end replacing
        .header__main-logo {
            display: flex;
            justify-content: center;
            height: 100%;
            position:relative;
            img {
                width: 100%;
            }
        }
    .header__main-logo.co-located-logo{
        position:absolute;
    }
    }
    .header__info {
          @media screen and (max-width: 990px) {
              margin-top: 35px;
          }
        
        &--date,
        &--location {
            display: block;
            font-size: 22px;
            font-weight: bold;
            //font-weight: normal // og-line
            //text-transform: uppercase; //og line
            text-align:left;
            margin: 0;
        }
        &--date {
            //color: $main-color;
            //margin-right: 20px; //og line
            margin: 0 20px 0 0;
        }
        &--location {
            color: #333333;
        }
        &--bonus {
            margin: 0;
            text-align:left;
            p {
                font-size: 16px;
                margin: 0;
            }
        }
    }
}

//Google translate hack
.VIpgJd-ZVi9od-ORHb-OEVmcd{
    display:none;
}
.container.google-translate-container {
    position:relative;
    z-index:1;
    @media only screen and (max-width: 767px) {
              top: 12px;
        //left: -100px;
        //z-index: 9999999;
        left: 0px;
        right: 0px;
    }
}
.header--default.header--desktop{
//margin-top:-40px;
}

#google_translate_element{
    height: 33px;
    overflow: hidden;
    position: absolute;
    z-index:99999;
    right:0px;

@media only screen and (max-width: 767px) {
   height: 20px;
        //right:0px;
        top:63px;
       text-align:right;
        z-index: auto;
      margin-right:12px;
       // left: 50%;
      //  transform: translate(-50%, -50%);
        
}
    @media only screen and (min-width: 1441px) {right:105px;}
    
}
.goog-te-gadget .goog-te-combo{
    margin:11px 10px!important;
  color:#6a6768;
    outline: none;
   
    @media only screen and (max-width: 767px) {
        margin:0!important;
      
    }
}
body{top:0!important;}



