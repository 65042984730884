.enhanced-exhibitors__cta {
	background-color: black;
	border: none;
	color: #fff;
	padding: 5px 10px 5px 10px;
	transition: opacity 0.3s;
	-webkit-transition: opacity 0.3s;
	a {
		text-decoration: none;
		color: unset;
		&:focus,
		&:hover {
			color: unset;
			text-decoration: none;
		}
	}
	&:hover {
		opacity: 0.8;
	}
	&:focus {
		border: none;
		outline: none;
		box-shadow: none;
	}
	&--enhanced-listing,
	&--premium-listing {
		position: absolute;
		right: 0;
		margin-top: -1px;
		z-index: 99;
		padding: 0;
		& > span {
			display: inline-block;
			&:first-child {
				background-color: black;
				padding: 5px 10px 5px 10px;
			}
			&:last-child {
				padding: 5px 10px 5px 10px;
				background-color: #5a5a5a;
				span {
					& + span {
						padding-left: 10px;
					}
				}
			}
		}
	}
	&--premium-listing {
		background-color: gold;
	}
	&--reset {
		right: 15px;
		position: absolute;
	}
	&--max {
		width: 100%;
		margin: 0px 0px 10px 0px;
		font-size: 2rem;
		font-weight: bold;
	}
	&--reg {
		background-color: #a92e25;
	}
	&.exhibitors__cta--checkbox-cta {
	    margin-bottom: 5px;
		color: initial;
		font-size: 1.5rem;
		padding: 10px 10px 10px 10px;
		border-left: 10px solid black;
		&.inactive {
			opacity: 0.3;
		}
		

		div {
			height: 100%;
			display: flex;
			align-items: center;
			.exhibitors__cta--checkbox-text {
				display: block;
				float: left;
			}
			.exhibitors__cta--checkbox-cta__checkbox {
				right: 25px;
				position: absolute;
				img {
					display: block;
					width: 15px;
					height: 15px;
				}
			}
		}
		&:last-child {
			margin-bottom: 10px;
		}
	}
}
