@import "./elem/slide", "./override/override", "./elem/mainNav", "./elem/secondary-nav", "./elem/alphabet";
$mobile-width: 992px;
.confman {
	&.conference-agenda {
		.react__body {
			margin-top: 30px;
			@media (max-width: 992px) {
				margin-top: 10px;
				& > .container-fluid {
					padding: 0;
					margin: 0;
					& > .row .col-12 {
						padding: 0;
						margin: 0;
					}
				}
			}
		}
		&--loading {
			min-height: 500px;
		}
		.conference-agenda__sessions {
			&.sessions--error-message {
				text-align: center;
			}
		}
	}
	.react__cta {
		height: fit-content;
		&.curved-cta--max {
			width: 100%;
			.curved-cta__link {
				font-size: 20px;
			}
		}
		&.curved-cta--hard {
		    	border-radius: unset;
			//transition: unset !important;
			//-webkit-transition: unset !important;
			&.curved-cta--reg {
				padding: 5px 20px 3px 20px;
			}
		
			.curved-cta__link {
				//transition: unset !important;
				//-webkit-transition: unset !important;
			}
		}
	}
}
.modal.modal--speaker-list {
	.modal-body {
		img {
			margin: auto;
			display: block;
		}
	}
}
